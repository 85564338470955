define('ember-cli-ifa/helpers/asset-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    assetMap: Ember.inject.service(),

    compute(params) {
      const file = params[0] || "";

      if (!file) {
        return;
      }

      return Ember.get(this, 'assetMap').resolve(file);
    }
  });
});