define("ember-date-components/templates/components/date-picker-month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oAGmVu4E",
    "block": "{\"symbols\":[\"date\",\"day\"],\"statements\":[[4,\"if\",[[23,[\"showWeekdays\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"date-picker__weekdays\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"weekdays\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"date-picker__weekday\"],[9],[0,\"\\n        \"],[1,[22,2,[]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"date-picker__days\"],[11,\"data-test-date-picker-days\",\"\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"daysInMonth\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[12,\"data-test-date-picker-day\",[28,[[22,1,[\"year\"]],\"-\",[22,1,[\"month\"]],\"-\",[22,1,[\"day\"]]]]],[12,\"data-date-picker-day\",[28,[[22,1,[\"year\"]],\"-\",[22,1,[\"month\"]],\"-\",[22,1,[\"day\"]]]]],[12,\"disabled\",[22,1,[\"disabled\"]]],[12,\"class\",[27,\"date-picker-day-classes\",[\"date-picker__day\"],[[\"isDisabled\",\"isInRange\",\"isToday\",\"isSelected\"],[[22,1,[\"disabled\"]],[22,1,[\"inRange\"]],[27,\"is-equal-day\",[[22,1,[\"date\"]],[23,[\"today\"]]],null],[27,\"is-equal-day\",[[22,1,[\"date\"]],[23,[\"selectedDates\"]]],null]]]]],[11,\"type\",\"button\"],[9],[0,\"\\n        \"],[1,[22,1,[\"day\"]],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"selectDate\",[22,1,[\"date\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"date-picker__day__placeholder\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-date-components/templates/components/date-picker-month.hbs"
    }
  });

  _exports.default = _default;
});