define("ember-cropster-common/components/c-textarea/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is a one-way textarea.
  
   * Note that the values DO NOT auto update, there is no two-way binding!
   *
   * @namespace Component
   * @class CTextarea
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * <label for="my-textarea">Textarea</label>
   * {{c-textarea
   *  onUpdate=(route-action "dummyAction")
   *  value="My initial value"
   *  id="my-textarea"
   * }}
   * ```
   */
  var _default = Ember.Component.extend({
    tagName: 'textarea',
    attributeBindings: ['value', 'accept', 'autocomplete', 'autosave', 'dir', 'disabled', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'inputmode', 'lang', 'list', 'name', 'pattern', 'placeholder', 'size', 'width', 'required', 'rows', 'cols'],

    /**
     * The value of the textarea. Note that this is not two-way data bound!
     *
     * @attribute value
     * @type {String}
     * @public
     */
    value: undefined,

    /**
     * If the textarea should be disabled. If it is, its value cannot be changed.
     *
     * @attribute disabled
     * @type {Boolean}
     * @optional
     * @public
     */
    disabled: false,

    /**
     * An optional placeholder for this textarea. Use a translated string for this.
     *
     * @attribute placeholder
     * @type {String}
     * @optional
     * @public
     */
    placeholder: null,

    /**
     * If filling/selection of this input is required.
     * Note that this will only do HTML5 validation, you will still need to validate inputs manually.
     *
     * @attribute required
     * @type {Boolean}
     * @optional
     * @public
     */
    required: false,

    /**
     * How many rows the textarea should have.
     *
     * @attribute rows
     * @type {Number}
     * @optional
     * @public
     */
    rows: null,

    /**
     * If this is set to true, the textarea will auto adjust its height to accomodate more content.
     *
     * @attribute autoresize
     * @type {Boolean}
     * @default false
     * @public
     */
    autoresize: false,

    /**
     * An optional parameter which will be appended to all actions sent from this component as second parameter.
     * This is useful to avoid having to create many similar actions, or to handle e.g. radio button goups.
     *
     * @attribute actionParam
     * @type {Mixed}
     * @public
     */
    actionParam: null,

    /**
     * The action to call when the value changes.
     *
     * @event onUpdate
     * @param {String} value
     * @public
     */
    onUpdate: null,
    update: null,
    _update: Ember.computed('onUpdate', 'update', function () {
      return this.onUpdate || this.update;
    }),
    KEY_EVENTS: Ember.computed(function () {
      return {
        '13': 'onenter',
        '27': 'onescape'
      };
    }),

    /**
     * The sanitized, internal value.
     *
     * @property _sanitizedValue
     * @type {Mixed}
     * @private
     */
    _sanitizedValue: undefined,

    /**
     * Set the height of the textarea if autoresize is true.
     *
     * @method _setTextareaHeight
     * @private
     */
    _setTextareaHeight() {
      let el = this.element;

      if (!Ember.get(this, 'autoresize') || !el) {
        return;
      }

      el.style.height = 'auto';
      el.style.height = `${el.scrollHeight}px`;
    },

    /**
     * Add the neccessary dom handlers.
     *
     * @method _initAutoExpandingTextarea
     * @private
     */
    _initAutoExpandingTextarea() {
      let el = this.element;

      if (!Ember.get(this, 'autoresize') || !el) {
        return;
      }

      el.style.overflow = 'hidden';
      el.style.resize = 'none';
      el.style.maxHeight = '400px';
      let rows = Ember.get(this, 'rows');

      if (rows) {
        el.style.minHeight = `${rows * 1.5}em`;
      }

      this._setTextareaHeight();
    },

    /**
     * On input event, handle the change event.
     *
     * @event input
     * @private
     */
    input() {
      this._handleChangeEvent();
    },

    /**
     * On change event, handle the change event.
     *
     * @event change
     * @private
     */
    change() {
      this._handleChangeEvent();
    },

    /**
     * On blur event, handle the blur event.
     *
     * @event blur
     * @private
     */
    focusOut() {
      this._handleBlurEvent();
    },

    /**
     * On key up action, handle the change event.
     *
     * @event keyUp
     * @private
     */
    keyUp(event) {
      this._interpretKeyEvents(event);
    },

    /**
     * Handle specific key events.
     * The user can also add special actions for these key events.
     *
     * @method _interpretKeyEvents
     * @param event
     * @private
     */
    _interpretKeyEvents(event) {
      let methodName = Ember.get(this, 'KEY_EVENTS')[event.keyCode];

      if (methodName) {
        this._sanitizedValue = null;

        this._processNewValue.call(this, methodName, this._readAppropriateAttr());
      }
    },

    /**
     * Process the new value and send a changed action.
     *
     * @method _handleChangeEvent
     * @private
     */
    _handleChangeEvent() {
      this._processNewValue.call(this, '_update', this._readAppropriateAttr());
    },

    /**
     * Process the new value and send a changed action.
     *
     * @method _handleBlurEvent
     * @private
     */
    _handleBlurEvent() {
      let value = this.sanitizeInput(this._readAppropriateAttr());
      let action = Ember.get(this, 'attrs.blurAction');

      if (action) {
        if (Ember.get(this, 'actionParam')) {
          action(value, Ember.get(this, 'actionParam'));
        } else {
          action(value);
        }
      }
    },

    /**
     * Get the appropriate value from the textarea.
     *
     * @method _readAppropriateAttr
     * @return {String|Boolean}
     * @private
     */
    _readAppropriateAttr() {
      return this.readDOMAttr('value');
    },

    /**
     * Process a new value and send a change action if it has changed.
     *
     * @method _processNewValue
     * @param {String} methodName The action name to send
     * @param {String|Boolean} rawValue The new value
     * @private
     */
    _processNewValue(methodName, rawValue) {
      let value = this.sanitizeInput(rawValue);
      let action = Ember.get(this, methodName);

      if (this._sanitizedValue !== value) {
        this._sanitizedValue = value;

        if (action) {
          if (Ember.get(this, 'actionParam')) {
            action(value, Ember.get(this, 'actionParam'));
          } else {
            action(value);
          }
        }

        this._setTextareaHeight();
      }
    },

    /**
     * This function can at some point be used to sanitize the input before it is passed to the action.
     *
     * @method _sanitizeInput
     * @param {String} input
     * @return {String}
     * @private
     */
    sanitizeInput(input) {
      return input;
    },

    /**
     * Update the sanitized value.
     *
     * @method didReceiveAttrs
     * @override
     * @private
     */
    didReceiveAttrs() {
      this._super(...arguments);

      let previousValue = this._sanitizedValue;
      let hadNoValue = !previousValue;
      let value = Ember.get(this, 'value');
      this._sanitizedValue = value; // Handle the case where the text area is rendered before a value is loaded
      // When a value is then loaded and set for an autoresize-textarea, it would not set the initial height correctly.

      if (Ember.get(this, 'autoresize') && hadNoValue && this._sanitizedValue) {
        new Ember.RSVP.Promise(resolve => {
          Ember.run.next(this, () => {
            this._setTextareaHeight();

            resolve();
          });
        });
      }
    },

    didInsertElement() {
      this._initAutoExpandingTextarea();
    }

  });

  _exports.default = _default;
});