define("ember-cropster-common/components/c-box/simple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oeAoxjaB",
    "block": "{\"symbols\":[\"box\",\"&default\"],\"statements\":[[4,\"c-box\",null,[[\"type\",\"class\"],[[23,[\"type\"]],[23,[\"class\"]]]],{\"statements\":[[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"header\"]],\"expected `box.header` to be a contextual component but found a string. Did you mean `(component box.header)`? ('ember-cropster-common/components/c-box/simple/template.hbs' @ L7:C7) \"],null]],null,{\"statements\":[[0,\"      \"],[1,[21,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"content\"]],\"expected `box.content` to be a contextual component but found a string. Did you mean `(component box.content)`? ('ember-cropster-common/components/c-box/simple/template.hbs' @ L11:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/c-box/simple/template.hbs"
    }
  });

  _exports.default = _default;
});