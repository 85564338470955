define("ember-cropster-common/components/info-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qNuZF5EL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"icon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"data-test-info-message-icon\",\"true\"],[11,\"class\",\"margin-top-3 margin-left-3 margin-bottom-3 self-center\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",[[23,[\"icon\"]]],[[\"class\"],[[23,[\"iconClass\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"flex flex-column flex-grow-1 margin-all-3\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"data-test-info-message-title\",\"true\"],[11,\"class\",\"margin-bottom-2 bold\"],[9],[0,\"\\n      \"],[1,[21,\"title\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"data-test-info-message-content\",\"true\"],[11,\"class\",\"line-height-3\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"onClose\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"self-start\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"data-test-info-message-close-btn\",\"true\"],[11,\"class\",\"info-message__close\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],[23,[\"onClose\"]]],null]],[9],[0,\"\\n      \"],[1,[27,\"svg-icon\",[\"close\"],[[\"class\"],[\"icon--small\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/info-message/template.hbs"
    }
  });

  _exports.default = _default;
});