define("ember-indexeddb/adapters/indexed-db", ["exports", "ember-data", "ember-indexeddb/utils/clone-deep"], function (_exports, _emberData, _cloneDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const JSONAPIAdapter = _emberData.default.JSONAPIAdapter;
  /**
   *
   * This Ember-Data adapter will fetch and save all data to/from IndexedDB.
   *
   * @module Ember Data
   * @class IndexedDbAdapter
   * @extends DS.JSONAPIAdapter
   * @public
   */

  var _default = JSONAPIAdapter.extend({
    /**
     * The indexedDb service.
     *
     * @property indexedDb
     * @type {IndexedDb}
     * @protected
     */
    indexedDb: Ember.inject.service(),

    /**
     * Coalesce all find requests.
     *
     * @property coalesceFindRequests
     * @type {Boolean}
     * @default true
     * @protected
     */
    coalesceFindRequests: true,

    /**
     * Set this to true to log durations of IndexedDB operations to the console.
     *
     * @property _shouldLogDurations
     * @type {Boolean}
     * @default false
     * @private
     */
    _shouldLogDurations: false,

    /**
     * This function will generate a GUID to be used in IndexedDB.
     *
     * @method generateIdForRecord
     * @return {String}
     * @protected
     */
    generateIdForRecord() {
      return Math.random().toString(32).slice(2).substr(0, 8);
    },

    /**
     * Fetch all records of a given type from IndexedDB.
     *
     * @method findAll
     * @param store
     * @param type
     * @return {Promise}
     * @public
     */
    findAll(store, type) {
      let indexedDB = Ember.get(this, 'indexedDb');
      let modelName = type.modelName;

      this._logDuration(`findAll ${modelName}`);

      return new Ember.RSVP.Promise((resolve, reject) => {
        indexedDB.findAll(modelName).then(records => {
          this._logDuration(`findAll ${modelName}`, true);

          let data = this._normalizeArray(records);

          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/findAll');
    },

    /**
     * Find a record of a given type & ID from IndexedDB.
     *
     * @method findRecord
     * @param store
     * @param type
     * @param {String} id
     * @return {Promise}
     * @public
     */
    findRecord(store, type, id) {
      let indexedDB = Ember.get(this, 'indexedDb');
      let modelName = type.modelName;

      this._logDuration(`findRecord ${modelName}/${id}`);

      return new Ember.RSVP.Promise((resolve, reject) => {
        indexedDB.find(modelName, id).then(record => {
          this._logDuration(`findRecord ${modelName}/${id}`, true);

          let data = this._normalizeSingle(record);

          if (!data) {
            reject(`findRecord ${modelName}/${id} failed!`);
            return;
          }

          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/findRecord');
    },

    /**
     * Find many records for a given type by multiple IDs.
     *
     * @method findMany
     * @param store
     * @param type
     * @param {Array} ids
     * @return {Promise}
     * @public
     */
    findMany(store, type, ids) {
      let indexedDB = Ember.get(this, 'indexedDb');
      let modelName = type.modelName;

      this._logDuration(`findMany ${modelName}/${ids.join(',')}`);

      return new Ember.RSVP.Promise((resolve, reject) => {
        indexedDB.find(modelName, ids).then(records => {
          this._logDuration(`findMany ${modelName}/${ids.join(',')}`, true);

          let data = this._normalizeArray(records);

          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/findMany');
    },

    /**
     * Query a type from IndexedDB.
     * This will try to use real indices where possible.
     *
     * @method query
     * @param store
     * @param type
     * @param {Object} query
     * @return {Promise}
     * @public
     */
    query(store, type, query) {
      let indexedDB = Ember.get(this, 'indexedDb');
      let modelName = type.modelName;
      let queryString = JSON.stringify(query);

      this._logDuration(`query ${modelName} ${queryString}`);

      return new Ember.RSVP.Promise((resolve, reject) => {
        indexedDB.query(modelName, query).then(records => {
          this._logDuration(`query ${modelName} ${queryString}`, true);

          let data = this._normalizeArray(records);

          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/query');
    },

    /**
     * Query a single item from IndexedDB.
     * This will try to use real indices where possible.
     *
     * @method queryRecord
     * @param store
     * @param type
     * @param {Object} query
     * @return {Promise}
     * @public
     */
    queryRecord(store, type, query) {
      let indexedDB = Ember.get(this, 'indexedDb');
      let modelName = type.modelName;

      this._logDuration(`queryRecord ${modelName}`);

      return new Ember.RSVP.Promise((resolve, reject) => {
        indexedDB.queryRecord(modelName, query).then(record => {
          this._logDuration(`queryRecord ${modelName}`, true);

          let data = this._normalizeSingle(record);

          if (!data) {
            resolve({
              data: null
            });
            return;
          }

          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/queryRecord');
    },

    /**
     * Update a given record in IndexedDB.
     *
     * @method updateRecord
     * @param store
     * @param type
     * @param snapshot
     * @return {Promise}
     * @public
     */
    updateRecord(store, type, snapshot) {
      return this._save(store, type, snapshot);
    },

    /**
     * Delete a record from IndexedDB.
     *
     * @method deleteRecord
     * @param store
     * @param type
     * @param snapshot
     * @return {Promise}
     * @public
     */
    deleteRecord(store, type, snapshot) {
      let indexedDB = Ember.get(this, 'indexedDb');
      let modelName = type.modelName;
      let id = Ember.get(snapshot, 'id');

      this._logDuration(`deleteRecord ${modelName}/${id}`);

      return new Ember.RSVP.Promise((resolve, reject) => {
        indexedDB.delete(modelName, id).then(() => {
          this._logDuration(`deleteRecord ${modelName}/${id}`, true);

          resolve(null);
        }, reject);
      }, 'indexedDbAdapter/deleteRecord');
    },

    /**
     * Create a new record in IndexedDB.
     *
     * @method createRecord
     * @param store
     * @param type
     * @param snapshot
     * @return {Promise}
     * @public
     */
    createRecord(store, type, snapshot) {
      return this._save(store, type, snapshot);
    },

    /**
     * This function is called under the hood by both `createRecord` and `updateRecord`.
     *
     * @method _save
     * @param store
     * @param type
     * @param snapshot
     * @return {Promise}
     * @private
     */
    _save(store, type, snapshot) {
      let indexedDB = Ember.get(this, 'indexedDb');
      let modelName = type.modelName;

      this._logDuration(`_save ${modelName}/${Ember.get(snapshot, 'id')}`);

      return new Ember.RSVP.Promise((resolve, reject) => {
        let data = {};
        let serializer = store.serializerFor(modelName);
        serializer.serializeIntoHash(data, type, snapshot, {
          includeId: true
        }); // We need to make a deep clone of the data, as the data is mutated by the store later

        let record = (0, _cloneDeep.cloneDeep)(data.data);
        indexedDB.save(modelName, record.id, record).then(() => {
          this._logDuration(`_save ${modelName}/${Ember.get(snapshot, 'id')}`, true);

          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/_save');
    },

    /**
     * This is used to normalize the response of IndexedDB for array responses.
     *
     * @method _normalizeArray
     * @param records
     * @return {Object}
     * @private
     */
    _normalizeArray(records) {
      if (!records) {
        return {
          data: []
        };
      }

      let data = Ember.A(records).compact();
      data = Ember.A(data).mapBy('json');
      return {
        data
      };
    },

    /**
     * This is used to normalize a single record response.
     *
     * @method _normalizeSingle
     * @param record
     * @return {Object}
     * @private
     */
    _normalizeSingle(record) {
      if (!record) {
        return null;
      }

      return {
        data: Ember.get(record, 'json')
      };
    },

    /**
     * This function is used to log durations of operations to the console, if `_shouldLogDurations` is set.
     *
     * @method _logDuration
     * @param str
     * @param isEnd
     * @private
     */
    _logDuration(str) {
      let isEnd = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (!Ember.get(this, '_shouldLogDurations')) {
        return;
      }
      /* eslint-disable no-console */


      if (isEnd) {
        console.timeEnd(str);
      } else {
        console.time(str);
      }
      /* eslint-enable no-console */

    }

  });

  _exports.default = _default;
});