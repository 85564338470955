define("ember-cropster-common/components/toast-container/message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zcHaPZEh",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"svg-icon\",[[23,[\"type\"]]],[[\"class\"],[\"toast-icon icon--inherit-color\"]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"toast-wrapper\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"toast-title\"],[11,\"data-test-toast-title\",\"\"],[9],[0,\"\\n      \"],[1,[21,\"title\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"message\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"toast-message\"],[11,\"data-test-toast-message\",\"\"],[9],[0,\"\\n      \"],[1,[27,\"format-nl2br\",[[23,[\"message\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"button\"],[11,\"class\",\"toast-close-button\"],[11,\"data-toast-close\",\"\"],[9],[0,\"\\n  \"],[1,[27,\"svg-icon\",[\"close\"],[[\"class\"],[\"icon--small icon--inherit-color\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"toast-progress-bar\"],[11,\"data-test-toast-progress-bar\",\"\"],[12,\"style\",[21,\"progressBarStyle\"]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/toast-container/message/template.hbs"
    }
  });

  _exports.default = _default;
});