define("ember-cropster-common/utils/serialize-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serializeArray = serializeArray;
  _exports.deserializeArray = deserializeArray;
  _exports.default = void 0;

  function serializeArray(arr) {
    if (!arr) {
      return null;
    }

    if (Ember.typeOf(arr) === 'array') {
      return arr.map(encodeURIComponent).join(',');
    }

    return arr;
  }

  function deserializeArray(value) {
    if (!value) {
      return [];
    }

    if (Ember.typeOf(value) === 'string' && value[0] === '[' && value[value.length - 1] === ']') {
      return JSON.parse(value);
    }

    if (Ember.typeOf(value) === 'string') {
      return value.split(',').map(decodeURIComponent);
    }

    return value;
  }

  var _default = {
    serializeArray,
    deserializeArray
  };
  _exports.default = _default;
});