define("ember-cropster-form/components/c-form/field/component", ["exports", "ember-cropster-form/components/c-form/field/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A generic, extensible field for the form.
   * It yields all field-related fields for you to use.
   *
   * @namespace Component.CForm
   * @class Field
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{#c-form/field
   *   model=model
   *   field='name'
   *   value='John'
   *   onChange=(action 'onChange')
   * as |fieldData|}}
   *   Current value: {{fieldData.value}}
   * {{/c-form/field}}
   * ```
   */
  var _default = Ember.Component.extend({
    layout: _template.default,

    /**
     * The current value of this field.
     *
     * @attribute value
     * @type {String}
     * @public
     */
    value: undefined,

    /**
     * The model of the form.
     *
     * @attribute model
     * @type {Object}
     * @public
     */
    model: undefined,

    /**
     * If validation errors should be shown or not.
     *
     * @attribute showError
     * @type {Boolean}
     * @public
     */
    showError: undefined,

    /**
     * The validations object for this field.
     * Expects an object from ember-cp-validation, with isInvalid & message fields.
     *
     * @attribute validations
     * @type {Object}
     * @public
     */
    validations: undefined,

    /**
     * The name of the field.
     *
     * @attribute field
     * @type {String}
     * @public
     */
    field: undefined,

    /**
     * The input ID of the field.
     *
     * @attribute fieldId
     * @type {String}
     * @public
     */
    fieldId: undefined,

    /**
     * The class(es) for the input.
     *
     * @attribute inputClass
     * @type {String}
     * @public
     */
    inputClass: undefined,

    /**
     * If the field is required.
     *
     * @attribute required
     * @type {Boolean}
     * @optional
     * @public
     */
    required: undefined,

    /**
     * If the field is disabled.
     *
     * @attribute disabled
     * @type {Boolean}
     * @optional
     * @public
     */
    disabled: undefined,

    /**
     * The action to call when the input value changes.
     *
     * @event onChange
     * @param {Mixed} value
     * @public
     */
    onChange: undefined,

    /**
     * The actual class to use for the input.
     * This is the inputClass, plus `is-error` if `showError` is true.
     *
     * @property actualInputClass
     * @type {String}
     * @readOnly
     * @protected
     */
    actualInputClass: Ember.computed('inputClass', 'showError', function () {
      return `${this.inputClass}${this.showError ? ' is-error' : ''}`;
    })
  });

  _exports.default = _default;
});