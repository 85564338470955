define("ember-cropster-common/components/toast-container/component", ["exports", "ember-cropster-common/components/toast-container/template", "ember-concurrency"], function (_exports, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Put this component in your app to render toast messages.
   * Manage the messages through the `toast` service.
   *
   * @namespace Component
   * @class ToastContainer
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    toast: Ember.inject.service(),
    keyboardShortcuts: Ember.inject.service(),
    toastItems: Ember.computed.readOnly('toast.toasts'),
    isTesting: Ember.computed(function () {
      let config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.environment === 'test';
    }),

    /**
     * Optionally render into another wormhole.
     * This defaults to 'toast-wormhole', and should be the ID of an empty div container to render into.
     *
     * @attribute wormholeElementId
     * @type {String}
     * @default 'modal-wormhole'
     * @public
     */
    wormholeElementId: Ember.computed(function () {
      if (this.isTesting) {
        return document.querySelector('#ember-testing > .ember-view').id;
      }

      return 'toast-wormhole';
    }),

    /**
     * The actual element in which the modal will be rendered into.
     *
     * @property wormholeElement
     * @type {DOMElement}
     * @readOnly
     * @protected
     */
    wormholeElement: Ember.computed('wormholeElementId', function () {
      let selector = `#${this.wormholeElementId}`;
      return document.querySelector(selector);
    }),
    actions: {
      removeToast(toastItem) {
        this.toast.removeToast(toastItem);
      }

    },

    didInsertElement() {
      this._super(...arguments);

      this._addKeyboardShortcuts(); // Sometimes, it can weirdly happen that this is not yet in the DOM
      // So we need to try again for this case


      this._ensureWormholeIsAdded.perform();
    },

    willRemoveElement() {
      this._super(...arguments);

      this._removeKeyboardShortcuts();
    },

    _ensureWormholeIsAdded: (0, _emberConcurrency.task)(function* () {
      if (this.wormholeElement) {
        return;
      }

      yield (0, _emberConcurrency.timeout)(10);
      this.notifyPropertyChange('wormholeElement');

      this._ensureWormholeIsAdded.perform();
    }),

    _addKeyboardShortcuts() {
      let keyboardShortcuts = this.keyboardShortcuts,
          toast = this.toast; // M+SHIFT+CTRL should remove all toasts

      let handler1 = keyboardShortcuts.registerKeyboardShortcut(toast.removeAllKeyboardLabel, {
        key: 'm',
        shiftKey: true,
        ctrlKey: true
      }, event => {
        event.preventDefault();
        this.toast.removeAllToasts();
      }); // M+CTRL should focus the first toast

      let handler2 = keyboardShortcuts.registerKeyboardShortcut(toast.focusFirstKeyboardLabel, {
        key: 'm',
        ctrlKey: true
      }, () => {
        let firstToastCloseButton = this.wormholeElement && this.wormholeElement.querySelector('[data-toast-close]');

        if (firstToastCloseButton) {
          firstToastCloseButton.focus();
        }
      });
      this._keyboardHandlers = [handler1, handler2];
    },

    _removeKeyboardShortcuts() {
      let keyboardHandlers = this._keyboardHandlers || [];
      keyboardHandlers.forEach(handler => handler.unregister());
    }

  });

  _exports.default = _default;
});