define('ember-cli-ifa/services/asset-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    enabled: false,
    map: Ember.computed(() => ({})),
    prepend: '/',

    resolve(name) {
      const map = Ember.get(this, 'map') || {};
      const prepend = Ember.get(this, 'prepend');
      const enabled = Ember.get(this, 'enabled');
      const assetName = enabled ? map[name] : name;

      return `${prepend}${assetName}`;
    }
  });
});