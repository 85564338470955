define('ember-country-flags/utils/country', ['exports', 'ember-country-flags/fixtures/countries'], function (exports, _countries) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getCountry = getCountry;
  exports.getCountryName = getCountryName;
  exports.getAllCountries = getAllCountries;
  function getCountry(code) {
    code = code.toUpperCase();
    return getAllCountries().findBy('code', code);
  }

  function getCountryName(code) {
    let country = getCountry(code);
    return country ? country.name : code;
  }

  function getAllCountries() {
    return Ember.A(_countries.default);
  }

  exports.default = getCountry;
});