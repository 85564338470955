define("ember-cropster-common/helpers/optional-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.optionalAction = optionalAction;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * A helper to mark a closure action as optional.
   *
   * @class OptionalAction
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{some-component
   *   event=(action (optional-action @optionalAction) someArg)}}
   * ```
   */
  function optionalAction(_ref) {
    let _ref2 = _slicedToArray(_ref, 1),
        action = _ref2[0];

    if (Ember.typeOf(action) === 'function') {
      return action;
    }

    return function () {};
  }

  var _default = Ember.Helper.helper(optionalAction);

  _exports.default = _default;
});