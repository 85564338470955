define('ember-keen/utils/performance-now', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = performanceNow;
  function performanceNow() {
    if (typeof fastboot === 'undefined' && 'performance' in window && typeof window.performance.now === 'function') {
      return window.performance.now();
    } else {
      return new Date().valueOf();
    }
  }
});