define("ember-cropster-common/components/dropdown-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kGBY3q4U",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\"],[12,\"class\",[28,[\"button button--dropdown \",[27,\"if\",[[23,[\"buttonClass\"]],[23,[\"buttonClass\"]],\"button--light\"],null],[27,\"if\",[[23,[\"buttonIcon\"]],\" button--icon\"],null],\"\\n    \",[27,\"if\",[[23,[\"isOpen\"]],\"button--dropdown--open\"],null]]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"toggle\"],null]],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"buttonIcon\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"svg-icon\",[[23,[\"buttonIcon\"]]],[[\"class\"],[[23,[\"buttonIconClass\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"\\n    \"],[1,[21,\"buttonText\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"dropdown-menu/dropdown-menu-content\",null,[[\"isOpen\",\"class\",\"alignLeft\",\"autoClose\",\"closeAction\"],[[23,[\"isOpen\"]],[23,[\"dropdownClass\"]],[23,[\"alignLeft\"]],[23,[\"autoClose\"]],[27,\"action\",[[22,0,[]],\"close\"],null]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/dropdown-menu/template.hbs"
    }
  });

  _exports.default = _default;
});