define('ember-country-flags/helpers/country-flag', ['exports', 'ember-country-flags/utils/country'], function (exports, _country) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.countryFlagPath = countryFlagPath;
  function countryFlagPath(languageCode) {
    if (!languageCode) {
      return null;
    }
    return `assets/img/flags/${languageCode.toLowerCase()}.png`;
  }

  /**
   * A helper to generate a flag image.
   * Pass in onlyUrl=true as argument, to just generate the URL without the <img> tag.
   *
   * @class CountryFlag
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{country-flag 'de'}}
   * ```
   */
  exports.default = Ember.Helper.extend({
    assetMap: Ember.inject.service(),

    _getPath(languageCode) {
      let path = countryFlagPath(languageCode);
      let assetMap = Ember.get(this, 'assetMap');

      let fullPath = assetMap.resolve(path);

      if (!fullPath.startsWith('http') && !fullPath.startsWith('/')) {
        return `/${fullPath}`;
      }

      return fullPath;
    },

    _generateImg(path, languageCode) {
      let countryName = (0, _country.getCountryName)(languageCode);
      return Ember.String.htmlSafe(`<img src='${path}' alt='${countryName}' class='country-flag'>`);
    },

    compute([languageCode], hash = {}) {
      let path = this._getPath(languageCode);

      if (hash.onlyUrl) {
        return path;
      }

      return this._generateImg(path, languageCode);
    }
  });
});