define("ember-cropster-common/utils/set-nested-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setNestedProperty;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function setNestedProperty(obj, property, value) {
    if (Ember.typeOf(obj) !== 'object' && Ember.typeOf(obj) !== 'instance') {
      throw new Error('The first argument for setNestedProperty must to be an object.');
    }

    if (Ember.typeOf(property) !== 'string' || property.length === 0) {
      throw new Error('The second argument for setNestedProperty must to be a string.');
    } // If the property is not nested, just set it


    let propertyPath = property.split('.');

    if (Ember.get(propertyPath, 'length') === 1) {
      return Ember.set(obj, property, value);
    } // If the property exists, simply overwrite it


    let exitingProperty = Ember.get(obj, property);

    if (exitingProperty) {
      return Ember.set(obj, property, value);
    } // Else, created the nested structure


    let currentObj = obj;

    let _propertyPath$splice = propertyPath.splice(-1),
        _propertyPath$splice2 = _slicedToArray(_propertyPath$splice, 1),
        lastProperty = _propertyPath$splice2[0];

    propertyPath.forEach(path => {
      // Check if the nested object already exists
      let newObj = Ember.get(currentObj, path);

      if (Ember.typeOf(newObj) !== 'object') {
        newObj = {};
        currentObj[path] = newObj;
      }

      currentObj = newObj;
    });
    currentObj[lastProperty] = value;
    return value;
  }
});