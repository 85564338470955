define("ember-cropster-common/components/c-box/simple/component", ["exports", "ember-cropster-common/components/c-box/simple/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    /*
     * Optional title used in the header of the component.
     *
     * @type {String}
     * @public
     */
    title: null
  });

  _exports.default = _default;
});