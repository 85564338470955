define("ember-cropster-common/utils/dom/scroll-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.scrollTo = scrollTo;
  _exports.default = void 0;

  function scrollTo(el, _ref) {
    let top = _ref.top,
        _ref$left = _ref.left,
        left = _ref$left === void 0 ? 0 : _ref$left,
        _ref$behavior = _ref.behavior,
        behavior = _ref$behavior === void 0 ? 'smooth' : _ref$behavior;

    try {
      el.scrollTo({
        top,
        left,
        behavior
      });
    } catch (e) {
      // Fall back to this if the other format fails
      // E.g. Samsung Internet does not support the object notation
      el.scrollTo(left, top);
    }
  }

  var _default = scrollTo;
  _exports.default = _default;
});