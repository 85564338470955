define('ember-country-flags/helpers/country-name', ['exports', 'ember-country-flags/utils/country'], function (exports, _country) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.countryName = countryName;
  function countryName([country]) {
    if (typeof country === 'string') {
      return (0, _country.getCountryName)(country);
    }

    if (typeof country === 'object' && country.name) {
      return country.name;
    }

    return country;
  }

  exports.default = Ember.Helper.helper(countryName);
});