define("ember-cropster-form/components/c-form/field-wrapper/label/component", ["exports", "ember-cropster-form/components/c-form/field-wrapper/label/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display the label for a form field.
   * This is rendered by the `c-form/field-wrapper` component.
   *
   * @namespace Component.CForm.FieldWrapper
   * @class Label
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{c-form/field-wrapper/label fieldId='test-field' label='Label content'}}
   * ```
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'label',
    attributeBindings: ['fieldId:for'],
    classNameBindings: ['required:label--required'],

    /**
     * The field ID this label is for.
     *
     * @attribute fieldId
     * @type {String}
     * @public
     */
    fieldId: null,

    /**
     * The label to display.
     *
     * @attribute label
     * @type {String}
     * @public
     */
    label: null,

    /**
     * If this field is required.
     *
     * @attribute required
     * @type {Boolean}
     * @default false
     * @public
     */
    required: false
  });

  _exports.default = _default;
});