define("ember-cropster-form/components/c-form/field/checkbox/component", ["exports", "ember-cropster-form/components/c-form/field/component", "ember-cropster-form/components/c-form/field/checkbox/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A checkbox field.
   *
   * @namespace Component.CForm.Field
   * @class Checkbox
   * @extends Component.CForm.Field
   * @public
   * @example
   * ```handlebars
   * {{c-form/field/checkbox
   *   field='isGlobal'
   *   value=false
   *   inputOptions=(hash
   *     checkboxLabel='Is global'
   *   )
   * }}
   * ```
   */
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: '',
    // Has basic fields from field

    /**
     * The inputOptions can contain these fields:
     *
     * * `checkboxLabel`
     * * `checkboxLabelClass`
     * * `checkboxInputClass`
     *
     * @attribute inputOptions
     * @type {Object}
     * @public
     */
    checkboxLabel: undefined,
    checkboxLabelClass: undefined,
    checkboxInputClass: undefined,

    didReceiveAttrs() {
      this._super(...arguments);

      this._parseOptions();
    },

    _parseOptions() {
      let options = this.inputOptions || {};
      let checkboxLabel = options.checkboxLabel,
          checkboxLabelClass = options.checkboxLabelClass,
          checkboxInputClass = options.checkboxInputClass;
      Ember.set(this, 'checkboxLabel', checkboxLabel);
      Ember.set(this, 'checkboxLabelClass', checkboxLabelClass);
      Ember.set(this, 'checkboxInputClass', checkboxInputClass);
    }

  });

  _exports.default = _default;
});