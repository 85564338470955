define("ember-cropster-form/components/c-form/field/radio/component", ["exports", "ember-cropster-form/components/c-form/field/component", "ember-cropster-form/components/c-form/field/radio/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A radio button group.
   *
   * @namespace Component.CForm.Field
   * @class Radio
   * @extends Component.CForm.Field
   * @public
   * @example
   * ```handlebars
   * {{#c-form/field/radio
   *   field='country'
   *   value='Austria'
   *   inputOptions=(hash
   *     radioOptions=(to-array 'Austria' 'Germany' 'United States')
   *   )
   * as |country}}
   *   {{country}}
   * {{/c-form/field/radio}}
   * ```
   */
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: '',
    // Has basic fields from field

    /**
     * The inputOptions can contain these fields:
     *
     * * `radioOptions` (required!)
     * * `radioLabelClass`
     * * `radioInputClass`
     *
     * @attribute inputOptions
     * @type {Object}
     * @public
     */
    radioOptions: undefined,
    radioLabelClass: undefined,
    radioInputClass: undefined,
    valueCompareFunction: undefined,
    // Private
    selectedIndex: Ember.computed('value', 'radioOptions.[]', function () {
      let value = this.value,
          radioOptions = this.radioOptions,
          valueCompareFunction = this.valueCompareFunction;
      return radioOptions.findIndex(opt => valueCompareFunction(opt, value));
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      this._parseOptions();
    },

    _parseOptions() {
      let options = this.inputOptions || {};
      let radioOptions = options.radioOptions,
          radioLabelClass = options.radioLabelClass,
          radioInputClass = options.radioInputClass,
          valueCompareFunction = options.valueCompareFunction;
      Ember.set(this, 'radioOptions', radioOptions);
      Ember.set(this, 'radioLabelClass', radioLabelClass);
      Ember.set(this, 'radioInputClass', radioInputClass);
      Ember.set(this, 'valueCompareFunction', valueCompareFunction || defaultCompareFunction);
      (false && !(radioOptions) && Ember.assert('You must pass `radioOptions` in the `inputOptions` for the radio input.', radioOptions));
    },

    actions: {
      select(i) {
        let radioOptions = this.radioOptions;
        let value = radioOptions[i];
        this.onChange(value);
      }

    }
  });

  _exports.default = _default;

  function defaultCompareFunction(a, b) {
    let typeA = Ember.typeOf(a);
    let typeB = Ember.typeOf(b);

    if (typeA === typeB && (typeA === 'instance' || typeA === 'object') && Ember.get(a, 'id') === Ember.get(b, 'id')) {
      return true;
    }

    return Ember.isEqual(a, b);
  }
});