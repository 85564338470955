define('ember-country-flags/services/asset-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    notEmberCliIfa: true,

    resolve(path) {
      return path;
    }

  });
});