define("ember-cropster-common/components/c-input-clearable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kqdovyDy",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"input-clearable \",[21,\"wrapperClass\"]]]],[11,\"data-test-input-clear-wrapper\",\"\"],[9],[0,\"\\n  \"],[5,\"c-input\",[[11,\"data-test-input-clear-input\",\"true\"],[13,1]],[[\"@value\",\"@update\",\"@class\",\"@id\",\"@placeholder\",\"@type\",\"@min\",\"@max\",\"@step\"],[[21,\"value\"],[21,\"onUpdate\"],[21,\"class\"],[21,\"id\"],[21,\"placeholder\"],[21,\"type\"],[21,\"min\"],[21,\"max\"],[21,\"step\"]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"value\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"input-clearable__button\"],[11,\"data-test-input-clear-button\",\"\"],[9],[0,\"\\n      \"],[1,[27,\"svg-icon\",[\"close\"],[[\"class\"],[\"icon--light-grey\"]]],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"clear\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/c-input-clearable/template.hbs"
    }
  });

  _exports.default = _default;
});