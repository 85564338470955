define("ember-cropster-common/utils/jsonapi-serializer", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serializeRelationshipValue = serializeRelationshipValue;
  _exports.jsonApiSerializer = jsonApiSerializer;
  _exports.default = void 0;

  function serializeRelationshipValue(value, property) {
    if (Ember.typeOf(value) === 'object') {
      return value;
    }

    if (!value || !Ember.get(value, 'id')) {
      return null;
    } // Note: This uses a private API and might break after updates


    let modelName = Ember.get(value, '_internalModel.modelName') || property;
    let relationshipType = (0, _emberInflector.pluralize)(Ember.String.camelize(modelName));
    return {
      type: relationshipType,
      id: Ember.get(value, 'id')
    };
  }

  function jsonApiSerializer(type) {
    let attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let relationships = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let relationshipsHash = {};
    Object.keys(relationships).forEach(property => {
      let value = relationships[property];

      if (Ember.typeOf(value) === 'array') {
        value = {
          data: value.map(value => serializeRelationshipValue(value, property))
        };
      } else {
        value = {
          data: serializeRelationshipValue(value, property)
        };
      }

      relationshipsHash[property] = value;
    });
    return {
      data: {
        type,
        attributes,
        relationships: relationshipsHash
      }
    };
  }

  var _default = jsonApiSerializer;
  _exports.default = _default;
});