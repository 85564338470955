if (typeof FastBoot === 'undefined') {
      var preferNative = true;
      (function (global) {
  define('fetch', ['exports'], function (self) {
    'use strict';

    var Promise = global.Ember.RSVP.Promise;
    var supportProps = ['FormData', 'FileReader', 'Blob', 'URLSearchParams', 'Symbol', 'ArrayBuffer'];
    var polyfillProps = ['fetch', 'Headers', 'Request', 'Response', 'AbortController'];
    var combinedProps = supportProps;
    if (preferNative) {
      combinedProps = supportProps.concat(polyfillProps);
    }
    combinedProps.forEach(function (prop) {
      if (global[prop]) {
        Object.defineProperty(self, prop, {
          configurable: true,
          get: function get() {
            return global[prop];
          },
          set: function set(v) {
            global[prop] = v;
          }
        });
      }
    });

    (function () {
      'use strict';

      class Emitter {
        constructor() {
          this.listeners = {};
        }
        addEventListener(type, callback) {
          if (!(type in this.listeners)) {
            this.listeners[type] = [];
          }
          this.listeners[type].push(callback);
        }
        removeEventListener(type, callback) {
          if (!(type in this.listeners)) {
            return;
          }
          const stack = this.listeners[type];
          for (let i = 0, l = stack.length; i < l; i++) {
            if (stack[i] === callback) {
              stack.splice(i, 1);
              return;
            }
          }
        }
        dispatchEvent(event) {
          if (!(event.type in this.listeners)) {
            return;
          }
          const debounce = callback => {
            setTimeout(() => callback.call(this, event));
          };
          const stack = this.listeners[event.type];
          for (let i = 0, l = stack.length; i < l; i++) {
            debounce(stack[i]);
          }
          return !event.defaultPrevented;
        }
      }

      class AbortSignal extends Emitter {
        constructor() {
          super();

          this.aborted = false;
          this.onabort = null;
        }
        toString() {
          return '[object AbortSignal]';
        }
        dispatchEvent(event) {
          if (event.type === 'abort') {
            this.aborted = true;
            if (typeof this.onabort === 'function') {
              this.onabort.call(this, event);
            }
          }

          super.dispatchEvent(event);
        }
      }

      class AbortController {
        constructor() {
          this.signal = new AbortSignal();
        }
        abort() {
          let event;
          try {
            event = new Event('abort');
          } catch (e) {
            if (typeof document !== 'undefined') {
              // For Internet Explorer 11:
              event = document.createEvent('Event');
              event.initEvent('abort', false, false);
            } else {
              // Fallback where document isn't available:
              event = {
                type: 'abort',
                bubbles: false,
                cancelable: false
              };
            }
          }
          this.signal.dispatchEvent(event);
        }
        toString() {
          return '[object AbortController]';
        }
      }

      if (typeof Symbol !== 'undefined' && Symbol.toStringTag) {
        // These are necessary to make sure that we get correct output for:
        // Object.prototype.toString.call(new AbortController())
        AbortController.prototype[Symbol.toStringTag] = 'AbortController';
        AbortSignal.prototype[Symbol.toStringTag] = 'AbortSignal';
      }

      function polyfillNeeded(self) {
        // Note that the "unfetch" minimal fetch polyfill defines fetch() without
        // defining window.Request, and this polyfill need to work on top of unfetch
        // so the below feature detection needs the !self.AbortController part.
        // The Request.prototype check is also needed because Safari versions 11.1.2
        // up to and including 12.1.x has a window.AbortController present but still
        // does NOT correctly implement abortable fetch:
        // https://bugs.webkit.org/show_bug.cgi?id=174980#c2
        return typeof self.Request === 'function' && !self.Request.prototype.hasOwnProperty('signal') || !self.AbortController;
      }

      (function (self) {

        if (!polyfillNeeded(self)) {
          return;
        }

        self.AbortController = AbortController;
        self.AbortSignal = AbortSignal;
      })(typeof self !== 'undefined' ? self : global);
    })();

    if (!self.fetch) {
      throw new Error('fetch is not defined - maybe your browser targets are not covering everything you need?');
    }

    var pending = 0;
    function decrement(result) {
      pending--;
      return result;
    }

    if (global.Ember.Test) {
      global.Ember.Test.registerWaiter(function () {
        return pending === 0;
      });

      self['default'] = function () {
        pending++;

        return self.fetch.apply(global, arguments).then(function (response) {
          response.clone().blob().then(decrement, decrement);
          return response;
        }, function (reason) {
          decrement(reason);
          throw reason;
        });
      };
    } else {
      self['default'] = self.fetch;
    }
    supportProps.forEach(function (prop) {
      delete self[prop];
    });
  });

  define('fetch/ajax', ['exports'], function () {
    throw new Error('You included `fetch/ajax` but it was renamed to `ember-fetch/ajax`');
  });
})(typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : this);
    }