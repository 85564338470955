define("ember-cropster-common/components/toast-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GpfwtRa1",
    "block": "{\"symbols\":[\"toastItem\"],\"statements\":[[4,\"if\",[[23,[\"wormholeElement\"]]],null,{\"statements\":[[4,\"in-element\",[[23,[\"wormholeElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"toast-container\"],[11,\"data-toast-container\",\"\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"toastItems\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"toast-container/message\",null,[[\"toastItem\",\"onRemove\",\"data-test-toast\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"removeToast\"],null],true]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/toast-container/template.hbs"
    }
  });

  _exports.default = _default;
});