define("ember-power-select/helpers/ember-power-select-is-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emberPowerSelectIsSelected = emberPowerSelectIsSelected;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  // TODO: Make it private or scoped to the component
  function emberPowerSelectIsSelected(_ref
  /* , hash*/
  ) {
    let _ref2 = _slicedToArray(_ref, 2),
        option = _ref2[0],
        selected = _ref2[1];

    if (selected === undefined || selected === null) {
      return false;
    }

    if (Ember.isArray(selected)) {
      for (let i = 0; i < selected.length; i++) {
        if (Ember.isEqual(selected[i], option)) {
          return true;
        }
      }

      return false;
    } else {
      return Ember.isEqual(option, selected);
    }
  }

  var _default = Ember.Helper.helper(emberPowerSelectIsSelected);

  _exports.default = _default;
});