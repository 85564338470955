define("ember-cropster-form/components/c-form/field-wrapper/validation/component", ["exports", "ember-cropster-form/components/c-form/field-wrapper/validation/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display the validations for a form field.
   * This is rendered by the `c-form/field-wrapper` component.
   *
   * @namespace Component.CForm.FieldWrapper
   * @class Validation
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{c-form/field-wrapper/validation
   *   field='name'
   *   validations=model.validations.attrs.name
   *   showError=true
   * }}
   * ```
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    /**
     * The field to show validations for.
     *
     * @attribute field
     * @type {String}
     * @public
     */
    field: null,

    /**
     * The validations object for this field.
     * Expect the format from ember-cp-validations, which is an object with a `message` property. (or null)
     *
     * @attribute validations
     * @type {Object}
     * @public
     */
    validations: null,

    /**
     * If an error should be shown.
     * Only if this is true will the validation be shown - even if there is a validation error.
     *
     * @attribute showError
     * @type {Boolean}
     * @default false
     * @public
     */
    showError: false
  });

  _exports.default = _default;
});