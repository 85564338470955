define("ember-cropster-common/components/loading-spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Gzza+iK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"loading-spinner \",[27,\"if\",[[23,[\"isCompact\"]],\"loading-spinner--compact\"],null],\" \",[21,\"loadingSpinnerClass\"]]]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/loading-spinner/template.hbs"
    }
  });

  _exports.default = _default;
});