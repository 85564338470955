define("ember-cropster-form/components/c-form/field/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QlK3Dfa7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isTypePassword\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"data-test-toggle-password-field\",\"\"],[11,\"class\",\"password-toggle-button button--plain\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",[\"see\"],[[\"class\"],[\"icon--inherit-color\"]]],false],[0,\"\\n\\n    \"],[7,\"span\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"actualType\"]],\"password\"],null]],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"Show\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"Hide\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"togglePasswordInput\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"c-input\",null,[[\"type\",\"id\",\"update\",\"value\",\"disabled\",\"min\",\"max\",\"step\",\"placeholder\",\"class\",\"autocomplete\",\"name\",\"data-test-form-field\"],[[23,[\"actualType\"]],[23,[\"fieldId\"]],[23,[\"onChange\"]],[23,[\"value\"]],[23,[\"disabled\"]],[23,[\"min\"]],[23,[\"max\"]],[23,[\"step\"]],[23,[\"placeholder\"]],[23,[\"actualInputClass\"]],[23,[\"autocomplete\"]],[23,[\"name\"]],[23,[\"field\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-form/components/c-form/field/text/template.hbs"
    }
  });

  _exports.default = _default;
});