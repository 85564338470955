define("ember-cropster-form/components/c-form/field/select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MhNCEn6t",
    "block": "{\"symbols\":[\"option\",\"&default\"],\"statements\":[[7,\"div\"],[12,\"data-test-form-field\",[21,\"field\"]],[9],[0,\"\\n\"],[4,\"component\",[[23,[\"componentName\"]]],[[\"options\",\"selected\",\"search\",\"disabled\",\"searchField\",\"allowClear\",\"triggerId\",\"triggerClass\",\"placeholder\",\"onchange\"],[[23,[\"selectOptions\"]],[23,[\"value\"]],[23,[\"search\"]],[23,[\"disabled\"]],[23,[\"searchField\"]],[23,[\"allowClear\"]],[23,[\"fieldId\"]],[23,[\"actualInputClass\"]],[23,[\"placeholder\"]],[23,[\"onChange\"]]]],{\"statements\":[[0,\"    \"],[14,2,[[22,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-form/components/c-form/field/select/template.hbs"
    }
  });

  _exports.default = _default;
});