define('ember-keen/utils/merge-deep', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mergeDeep;


  function isObject(obj) {
    return Ember.typeOf(obj) === 'object';
  }

  function isArray(obj) {
    return Ember.typeOf(obj) === 'array';
  }

  function isCloneableInstance(obj) {
    return Ember.typeOf(obj) === 'instance' && Ember.typeOf(obj.clone) === 'function';
  }

  /**
   * Deep-merge objects without mutating them.
   * In contrast to e.g. $.extend(), this will NOT mutate the first given parameter, but just return the merged object.
   *
   * @namespace EmberCropsterCommon.Util
   * @method mergeDeep
   * @param {...Object} objects A list of objects to merge
   * @return {Object} The merged object
   * @public
   */
  function mergeDeep(...objects) {
    return objects.reduce((prev, obj) => {
      Object.keys(obj).forEach(key => {
        let pVal = prev[key];
        let oVal = obj[key];

        prev[key] = mergeValues(pVal, oVal);
      });

      return prev;
    }, {});
  }

  function mergeValues(pVal, oVal) {
    if (isArray(oVal)) {
      return mergeArrays(pVal, oVal);
    }
    if (isObject(oVal)) {
      return mergeObjects(pVal, oVal);
    }
    if (isCloneableInstance(oVal)) {
      return oVal.clone();
    }
    return oVal;
  }

  function mergeArrays(pVal, oVal) {
    let arr = [];
    if (isArray(pVal)) {
      arr = arr.concat(...pVal);
    }

    if (isArray(oVal)) {
      arr = arr.concat(...oVal);
    }
    return arr;
  }

  function mergeObjects(pVal, oVal) {
    if (isObject(pVal)) {
      return mergeDeep(pVal, oVal);
    }

    return mergeDeep(oVal);
  }
});