define("ember-cropster-common/utils/merge-deep", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mergeDeep;

  function isObject(obj) {
    return Ember.typeOf(obj) === 'object' && !_moment.default.isMoment(obj);
  }

  function isArray(obj) {
    return Ember.typeOf(obj) === 'array';
  }

  function isCloneableInstance(obj) {
    return Ember.typeOf(obj) === 'instance' && Ember.typeOf(obj.clone) === 'function';
  }
  /**
   * Deep-merge objects without mutating them.
   * In contrast to e.g. $.extend(), this will NOT mutate the first given parameter, but just return the merged object.
   *
   * @namespace EmberCropsterCommon.Util
   * @method mergeDeep
   * @param {...Object} objects A list of objects to merge
   * @return {Object} The merged object
   * @public
   */


  function mergeDeep() {
    for (var _len = arguments.length, objects = new Array(_len), _key = 0; _key < _len; _key++) {
      objects[_key] = arguments[_key];
    }

    return objects.reduce((prev, obj) => {
      Object.keys(obj).forEach(key => {
        let pVal = prev[key];
        let oVal = obj[key];
        prev[key] = mergeValues(pVal, oVal);
      });
      return prev;
    }, {});
  }

  function mergeValues(pVal, oVal) {
    if (isArray(oVal)) {
      return mergeArrays(pVal, oVal);
    }

    if (isObject(oVal)) {
      return mergeObjects(pVal, oVal);
    }

    if (_moment.default.isMoment(oVal)) {
      return oVal.clone();
    }

    if (isCloneableInstance(oVal)) {
      return oVal.clone();
    }

    return oVal;
  }

  function mergeArrays(pVal, oVal) {
    let arr = [];

    if (isArray(pVal)) {
      arr = arr.concat(...pVal);
    }

    if (isArray(oVal)) {
      arr = arr.concat(...oVal);
    }

    return arr;
  }

  function mergeObjects(pVal, oVal) {
    if (isObject(pVal)) {
      return mergeDeep(pVal, oVal);
    }

    return mergeDeep(oVal);
  }
});