define("ember-indexeddb/services/indexed-db", ["exports", "ember-indexeddb/utils/test-waiter", "ember-concurrency", "ember-indexeddb/utils/log"], function (_exports, _testWaiter, _emberConcurrency, _log) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Dexie */

  /**
   * This service allows interacting with an IndexedDB database.
   *
   * @module Services
   * @class IndexedDb
   * @extends Ember.Service
   * @public
   */
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    indexedDbConfiguration: Ember.inject.service(),

    /**
     * The actual Dexie database.
     *
     * @property db
     * @type {Dexie}
     * @public
     */
    db: null,

    /**
     * The database name to use.
     * Overwrite this if you want to use something different.
     *
     * @property databaseName
     * @type {String}
     * @default 'ember-indexeddb'
     * @public
     */
    databaseName: 'ember-indexeddb',

    /**
     * This is an object with an array per model type.
     * It holds all the objects per model type that should be bulk saved.
     * After actually saving, this will be cleared.
     *
     * @property _saveQueue
     * @type {Object}
     * @private
     */
    _saveQueue: null,

    /**
     * This is the test waiter used to ensure all promises are resolved in tests.
     * This is set by the this._registerTestWaiter() method.
     *
     * @property _testWaiter
     * @type {Function}
     * @private
     */
    _testWaiter: null,

    /**
     * This is a promise that is used for bulk saving.
     * All bulkSave() operations use and return the same promise, which is cached here.
     *
     * @property _savePromise
     * @type {Promise}
     * @private
     */
    _savePromise: null,

    /**
     * All currently running promises are temporarily saved here.
     * This is used to check if there are running transactions.
     *
     * @property _promiseQueue
     * @type {Promise[]}
     * @private
     */
    _promiseQueue: null,

    /**
     * e.g. MS Edge doesn't support compound indices.
     * For these cases, querying shouldn't try to use them.
     *
     * @property _supportsCompoundIndicies
     * @type {Boolean}
     * @readOnly
     * @private
     */
    _supportsCompoundIndices: Ember.computed(function () {
      try {
        window.IDBKeyRange.only([1]);
      } catch (e) {
        return false;
      }

      return true;
    }),

    /**
     * Call this and wait until it resolves before doing anything with IndexedDB!
     * This should be done in beforeModel() on the application route.
     * It will reject if IndexedDB is not available.
     *
     * Also available as a task: `indexedDb.setupTask.perform()`
     *
     * @method setup
     * @return {Promise}
     * @public
     */
    setup() {
      return Ember.get(this, 'setupTask').perform();
    },

    setupTask: (0, _emberConcurrency.task)(function* () {
      if (Ember.get(this, 'db')) {
        return Ember.get(this, 'db');
      }

      let testWaiter = function testWaiter() {
        return false;
      };

      (0, _testWaiter.registerWaiter)(testWaiter);
      let db = new window.Dexie(Ember.get(this, 'databaseName'));
      let dbConfiguration = Ember.get(this, 'indexedDbConfiguration');
      dbConfiguration.setupDatabase(db);
      Ember.set(this, 'db', db);
      yield openDb(db);
      (0, _testWaiter.unregisterWaiter)(testWaiter);
      return db;
    }),

    /**
     * Query indexedDB.
     * This uses _buildQuery under the hood.
     * This resolved to an array.
     *
     * @method query
     * @param {String} type The model type to query
     * @param {Object} query The query data
     * @return {Promise[]}
     * @public
     */
    query(type, query) {
      let queryPromise = this._buildQuery(type, query);

      let promise = new Ember.RSVP.Promise((resolve, reject) => queryPromise.toArray().then(resolve, reject), 'indexedDb/query');

      this._addToPromiseQueue(promise);

      return promise;
    },

    /**
     * Query indexedDB.
     * This uses _buildQuery under the hood.
     * This resolved to an object.
     *
     * @method queryRecord
     * @param {String} type The model type to query
     * @param {Object} query The query data
     * @return {Promise}
     * @public
     */
    queryRecord(type, query) {
      let queryPromise = this._buildQuery(type, query);

      let promise = new Ember.RSVP.Promise((resolve, reject) => queryPromise.first().then(resolve, reject), 'indexedDb/queryRecord');

      this._addToPromiseQueue(promise);

      return promise;
    },

    /**
     * Find one or multiple items by id.
     * If id is an array, this will try to fetch all of these objects and resolve with an array.
     * Otherwise, it will resolve with an object.
     *
     * @method find
     * @param {String} type The model type to find
     * @param {String|String[]} id One or multiple ids to fetch
     * @return {Promise}
     * @public
     */
    find(type, id) {
      let db = Ember.get(this, 'db');

      if (Ember.typeOf(id) === 'array') {
        return db[type].where('id').anyOf(id.map(this._toString)).toArray();
      }

      let promise = new Ember.RSVP.Promise((resolve, reject) => db[type].get(this._toString(id)).then(resolve, reject), 'indexedDb/find');

      this._addToPromiseQueue(promise);

      return promise;
    },

    /**
     * Find all of a given type.
     *
     * @method findAll
     * @param {String} type The model type to find.
     * @return {Promise}
     * @public
     */
    findAll(type) {
      let db = Ember.get(this, 'db');
      let promise = new Ember.RSVP.Promise((resolve, reject) => db[type].toArray().then(resolve, reject), 'indexedDb/findAll');

      this._addToPromiseQueue(promise);

      return promise;
    },

    /**
     * Add one or multiple items to the database.
     *
     * @method add
     * @param {String} type The model type to add
     * @param {Object|Object[]} items One or multiple objects to add to the database.
     * @return {Promise}
     * @public
     */
    add(type, items) {
      let db = Ember.get(this, 'db'); // Single Item?

      if (Ember.typeOf(items) !== 'array') {
        items = Ember.A([items]);
      }

      let data = items.map(item => {
        return this._mapItem(type, item);
      });
      let promise = new Ember.RSVP.Promise((resolve, reject) => db[type].bulkPut(data).then(resolve, reject), 'indexedDb/add');

      this._addToPromiseQueue(promise);

      return promise;
    },

    /**
     * Save/update an item.
     *
     * @method save
     * @param {String} type The model type of the object
     * @param {String} id The id of the object
     * @param {Object} item The serialized object to save.
     * @return {Promise}
     * @public
     */
    save(type, id, item) {
      let db = Ember.get(this, 'db');

      let data = this._mapItem(type, item);

      let promise = new Ember.RSVP.Promise((resolve, reject) => db[type].put(data).then(resolve, reject), 'indexedDb/save');

      this._addToPromiseQueue(promise);

      return promise;
    },

    /**
     * This will wait for 10ms and try to build a queue, and save everything at once if possible.
     *
     * @method saveBulk
     * @param {String} type The model type to save
     * @param {Object} item The data to save
     * @return {Promise}
     * @public
     */
    saveBulk(type, item) {
      let savePromise = Ember.get(this, '_savePromise');
      let saveQueue = Ember.get(this, '_saveQueue'); // If no save promise exists, create a new one

      if (!savePromise) {
        savePromise = new Ember.RSVP.Promise((resolve, reject) => {
          Ember.run.later(this, () => {
            this._bulkSave().then(val => {
              Ember.set(this, '_savePromise', null);
              resolve(val);
            }, reject);
          }, 100);
        }, 'indexedDb/saveBulk');
        Ember.set(this, '_savePromise', savePromise);

        this._addToPromiseQueue(savePromise);
      }

      let queue = Ember.get(saveQueue, type);

      if (!queue) {
        queue = Ember.A();
        saveQueue[type] = queue;
      }

      queue.pushObject(item);
      return savePromise;
    },

    /**
     * Clear a database table.
     *
     * @method clear
     * @param {String} type The model type to clear.
     * @return {Promise}
     * @public
     */
    clear(type) {
      let db = Ember.get(this, 'db');
      let promise = new Ember.RSVP.Promise((resolve, reject) => db[type].clear().then(resolve, reject), 'indexedDb/clear');

      this._addToPromiseQueue(promise);

      return promise;
    },

    /**
     * Delete one item.
     *
     * @method delete
     * @param {String} type The model type to delete
     * @param {String} id The id of the entry to delete
     * @return {Promise}
     * @public
     */
    delete(type, id) {
      let db = Ember.get(this, 'db');
      let promise = new Ember.RSVP.Promise((resolve, reject) => db[type].delete(id).then(resolve, reject), 'indexedDb/delete');

      this._addToPromiseQueue(promise);

      return promise;
    },

    /**
     * Drop the entire database.
     *
     * Also available as a task: `indexedDb.dropDatabaseTask.perform()`
     *
     * @method dropDatabase
     * @return {Promise}
     * @public
     */
    dropDatabase() {
      return Ember.get(this, 'dropDatabaseTask').perform();
    },

    dropDatabaseTask: (0, _emberConcurrency.task)(function* () {
      let db = Ember.get(this, 'db');

      if (!db) {
        return;
      }

      let testWaiter = function testWaiter() {
        return false;
      };

      (0, _testWaiter.registerWaiter)(testWaiter); // Ensure the db is open

      yield openDb(db);
      yield Ember.get(this, 'waitForQueueTask').perform();
      yield (0, _emberConcurrency.timeout)(100);
      yield db.delete();
      yield closeDb(db);
      Ember.set(this, 'db', null);
      (0, _testWaiter.unregisterWaiter)(testWaiter);
    }),

    /**
     * Export a complete dump of the current database.
     * The output of this can be used to recreate the exact database state via this.importDatabase(config);
     *
     * Also available as a task: `indexedDb.exportDatabaseTask.perform()`
     *
     * @method exportDatabase
     * @return {Promise}
     * @public
     */
    exportDatabase() {
      let promise = Ember.get(this, 'exportDatabaseTask').perform();

      this._addToPromiseQueue(promise);

      return promise;
    },

    exportDatabaseTask: (0, _emberConcurrency.task)(function* () {
      let db = Ember.get(this, 'db');
      let config = {
        databaseName: Ember.get(this, 'databaseName'),
        version: Ember.get(this, 'currentVersion'),
        stores: {},
        data: {}
      }; // Now, open database without specifying any version. This will make the database open any existing database and read its schema automatically.

      yield openDb(db); // Save the last version number

      Ember.set(config, 'version', db.verno);
      let promises = Ember.A();
      db.tables.forEach(function (table) {
        let primKeyAndIndexes = [table.schema.primKey].concat(table.schema.indexes);
        let schemaSyntax = primKeyAndIndexes.map(function (index) {
          return index.src;
        }).join(',');
        Ember.set(config.stores, table.name, schemaSyntax);
        let promise = table.each(object => {
          let arr = Ember.get(config.data, table.name);

          if (!arr) {
            arr = Ember.A();
            Ember.set(config.data, table.name, arr);
          }

          arr.push(object);
        });
        promises.push(promise);
      });
      yield Ember.RSVP.Promise.all(promises);
      return config;
    }),

    /**
     * Import a complete database dump as created by this.exportDatabase()
     *
     * Also available as a task: `indexedDb.importDatabaseTask.perform()`
     *
     * @method importDatabase
     * @param {Object} config A configuration object as created by this.exportDatabase()
     * @return {Promise}
     * @public
     */
    importDatabase(config) {
      let promise = Ember.get(this, 'importDatabaseTask').perform(config);

      this._addToPromiseQueue(promise);

      return promise;
    },

    importDatabaseTask: (0, _emberConcurrency.task)(function* (config) {
      let databaseName = config.databaseName,
          version = config.version,
          stores = config.stores,
          data = config.data;
      (0, _log.log)('====================================');
      (0, _log.log)('Importing database dump!');
      (0, _log.log)('Dropping existing database...');
      yield Ember.get(this, 'dropDatabaseTask').perform();
      (0, _log.log)(`Setting up database ${databaseName} in version ${version}...`);
      let db = new Dexie(databaseName);
      db.version(version).stores(stores);
      (0, _log.log)('Opening database...');
      yield openDb(db);
      let tables = Object.keys(data);

      while (tables.length) {
        let table = tables.shift();
        (0, _log.log)(`Importing ${data[table].length} rows for ${table}...`);
        yield db[table].bulkPut(data[table]);
      } // This is closed here, don't forget to call 'setup' again, to do eventually necessary migrations


      yield closeDb(db);
      (0, _log.log)('Database import done!');
    }),

    /**
     * Wait for all queued objects ot be resolved.
     * This will resolve when there are no open processes anymore.
     *
     * Also available as a task: `indexedDb.waitForQueueTask.perform()`
     *
     * @method waitForQueue
     * @return {Promise}
     * @public
     */
    waitForQueue() {
      return Ember.get(this, 'waitForQueueTask').perform();
    },

    waitForQueueTask: (0, _emberConcurrency.task)(function* () {
      while (Ember.get(this, '_promiseQueue.length') || Ember.get(this, '_savePromise')) {
        yield (0, _emberConcurrency.timeout)(100);
      }
    }),

    /**
     * Get the queue and save everything in it in bulk.
     *
     * @method _bulkSave
     * @private
     */
    _bulkSave() {
      let saveQueue = Ember.get(this, '_saveQueue');
      let promises = Ember.A();

      for (let i in saveQueue) {
        promises.push(this.add(i, saveQueue[i]));
        saveQueue[i] = Ember.A();
      }

      return Ember.RSVP.Promise.all(promises, 'indexedDb/_bulkSave');
    },

    /**
     * Build a query for Dexie.
     *
     * This will try to find a matching index, and use it if possible.
     * It can also handle multi-indecies, if they have been specified and are supported.
     * If no matching index is found, it will fetch everything and try to filter it via JS.
     * Note that this is _much_ slower than querying by actual indecies, so you should definitely use that if possible!
     * If you are using multiple query arguments, and only one of them is found as index, it will query the database by this index
     * and then do the other queries via JS filter.
     *
     * Note that this will also auto-convert boolean query arguments to 1/0.
     *
     * @method _buildQuery
     * @param {String} type The model type to query
     * @param {Object} query The actual query
     * @return {Dexie.Collection}
     * @private
     */
    _buildQuery(type, query) {
      let db = Ember.get(this, 'db');

      let _supportsCompoundIndices = Ember.get(this, '_supportsCompoundIndices');

      let promise = null;
      let keys = Object.keys(query); // Convert boolean queries to 1/0

      for (let i in query) {
        if (Ember.typeOf(query[i]) === 'boolean') {
          query[i] = query[i] ? 1 : 0;
        }
      } // Only one, then do a simple where


      if (keys.length === 1) {
        /* eslint-disable ember-suave/prefer-destructuring */
        let key = keys[0];
        /* eslint-enable ember-suave/prefer-destructuring */

        return db[type].where(key).equals(query[key]);
      } // Order of query params is important!


      let schema = db[type].schema;
      let indexes = schema.indexes; // try to find a fitting multi index
      // only if the client supports compound indices!

      if (_supportsCompoundIndices) {
        let multiIndex = indexes.find(index => {
          let keyPath = Ember.get(index, 'keyPath'); // If keyPath is not set, not an array or not the same length as the keys, it's not the correct one

          if (!keyPath || Ember.typeOf(keyPath) !== 'array' || keyPath.length !== keys.length) {
            return false;
          } // If one of the keys is not in the keyPath, return false


          return !keys.find(key => !keyPath.includes(key));
        }); // If a multi index is found, use it

        if (multiIndex) {
          let keyPath = Ember.get(multiIndex, 'keyPath');
          let compareValues = Ember.A();
          keyPath.forEach(key => {
            compareValues.push(query[key]);
          });
          let keyName = Ember.get(multiIndex, 'name');
          return db[type].where(keyName).equals(compareValues);
        }
      } // Else, filter manually


      Object.keys(query).forEach(i => {
        if (!promise) {
          promise = db[type].where(i).equals(query[i]);
        } else {
          promise = promise.and(item => Ember.get(item, i) === query[i]);
        }
      });
      return promise;
    },

    _mapItem(type, item) {
      let dbConfiguration = Ember.get(this, 'indexedDbConfiguration');
      return dbConfiguration.mapItem(type, item);
    },

    _toString(val) {
      return `${val}`;
    },

    /**
     * Add a promise to the promise queue.
     * When the promise resolves or rejects, it will be removed from the promise queue.
     *
     * @method _addToPromiseQueue
     * @param {Promise} promise
     * @return {Promise}
     * @private
     */
    _addToPromiseQueue(promise) {
      let promiseQueue = Ember.get(this, '_promiseQueue');
      promiseQueue.pushObject(promise);

      let removeObject = () => {
        promiseQueue.removeObject(promise);
      };

      promise.finally(removeObject);
      return promise;
    },

    /**
     * Register the test waiter.
     * This waiter checks if there are no promises left in the _promiseQueue.
     *
     * @method _registerTestWaiter
     * @private
     */
    _registerTestWaiter() {
      let testWaiter = () => {
        return Ember.get(this, '_promiseQueue.length') === 0;
      };

      (0, _testWaiter.registerWaiter)(testWaiter);
      Ember.set(this, '_testWaiter', testWaiter);
    },

    /**
     * This removes the test waiter.
     *
     * @method _unregisterTestWaiter
     * @private
     */
    _unregisterTestWaiter() {
      let testWaiter = Ember.get(this, '_testWaiter');
      (0, _testWaiter.unregisterWaiter)(testWaiter);
    },

    init() {
      this._super(...arguments);

      Ember.set(this, '_saveQueue', {});
      Ember.set(this, '_promiseQueue', Ember.A());

      this._registerTestWaiter();
    },

    willDestroy() {
      this._unregisterTestWaiter();

      this._super(...arguments);
    }

  });

  _exports.default = _default;

  async function openDb(db) {
    while (!db.isOpen()) {
      await db.open();
    }

    return db;
  }

  async function closeDb(db) {
    while (db.isOpen()) {
      await db.close();
    }

    return db;
  }
});