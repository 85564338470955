define("ember-cropster-common/components/c-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bPpTrTGj",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[5,\"c-input\",[[11,\"data-test-checkbox\",\"true\"],[13,1]],[[\"@type\",\"@class\",\"@id\",\"@checked\",\"@onUpdate\"],[\"checkbox\",[21,\"class\"],[21,\"id\"],[21,\"checked\"],[27,\"action\",[[22,0,[]],\"onUpdate\"],null]]]],[0,\"\\n\"],[7,\"label\"],[12,\"for\",[21,\"id\"]],[12,\"class\",[21,\"labelClass\"]],[9],[1,[21,\"label\"],false],[14,2],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/c-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});