define("ember-cropster-form/components/c-form/field-wrapper/validation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GTcSOTlO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"showError\"]],[23,[\"validations\",\"message\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"color-error text--smaller margin-top-xsmall\"],[12,\"data-test-form-field-validation\",[21,\"field\"]],[9],[0,\"\\n    \"],[1,[23,[\"validations\",\"message\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-form/components/c-form/field-wrapper/validation/template.hbs"
    }
  });

  _exports.default = _default;
});