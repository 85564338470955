define("ember-cropster-form/components/c-form/field/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WPhdS52j",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"c-input\",null,[[\"type\",\"id\",\"update\",\"checked\",\"class\",\"disabled\",\"data-test-form-field\"],[\"checkbox\",[23,[\"fieldId\"]],[23,[\"onChange\"]],[23,[\"value\"]],[23,[\"checkboxInputClass\"]],[23,[\"disabled\"]],[23,[\"field\"]]]]],false],[0,\"\\n\\n\"],[7,\"label\"],[12,\"for\",[21,\"fieldId\"]],[12,\"class\",[21,\"checkboxLabelClass\"]],[9],[0,\"\\n  \"],[1,[21,\"checkboxLabel\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-form/components/c-form/field/checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});