define("ember-cropster-common/components/scroll-in-view/component", ["exports", "ember-cropster-common/components/scroll-in-view/template", "ember-cropster-common/utils/dom/element-offset", "ember-concurrency", "ember-cropster-common/utils/dom/event-listener"], function (_exports, _template, _elementOffset, _emberConcurrency, _eventListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A component which sends an action if it comes into the viewport.
   *
   * @namespace Component
   * @class ScrollInView
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{#scroll-in-view action=(route-action 'dummyAction')}}
   *   <div>When this comes into view, the action is called!</div>
   * {{/scroll-in-view}}
   * ```
   */
  var _default = Ember.Component.extend({
    layout: _template.default,

    /**
     * The threshold to use for the intersection check.
     * The default is 0.1 = 10% of the element must be visible.
     *
     * @attribute threshold
     * @type {Number}
     * @default 0.1
     * @public
     */
    threshold: 0.1,

    /**
     * The action to call when the element comes into the viewport.
     *
     * @event action
     * @public
     */
    action: null,

    /**
     * An optional action to call when the element goes out of view.
     * This only works if intersection observer is available.
     *
     * @event endAction
     * @public
     */
    endAction: null,
    canUseIntersectionObserver: Ember.computed(function () {
      return 'IntersectionObserver' in window;
    }),
    _isTesting: Ember.computed(function () {
      return Ember.testing; // eslint-disable-line ember-suave/no-direct-property-access
    }),
    // This is just for tests, where we don't scroll the window itself, but the test container
    _scrollContainer: Ember.computed(function () {
      return this._isTesting ? document.querySelector('#ember-testing-container') : window;
    }),

    /**
     * Checks the scroll position and sends the action if appropriate.
     * This is only used if intersection observer is not supported
     *
     * @method _checkScrollPositionTask
     * @private
     */
    _checkScrollPositionTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(50);
      let el = this.element;
      let window = Ember.get(this, '_scrollContainer');

      if (!el || !window) {
        return;
      }

      let isTesting = Ember.get(this, '_isTesting');
      let container = isTesting ? window : document.body;
      let threshold = Ember.get(this, 'threshold') || 0;
      let scrollOffset = el.offsetHeight * threshold;
      let windowScrollTop = container.scrollTop;
      let windowHeight = isTesting ? container.offsetHeight : window.innerHeight;
      let scrollTop = windowScrollTop + windowHeight + scrollOffset; // When testing, the test container is scaled to 0.5
      // So we need to reduce the offset top of the element by half to account for that

      let offset = isTesting ? el.offsetTop / 2 : (0, _elementOffset.default)(el).top;

      if (scrollTop >= offset) {
        this._sendAction();
      }
    }).restartable(),

    _sendAction() {
      let action = Ember.get(this, 'action');
      action();
    },

    _sendEndAction() {
      let endAction = Ember.get(this, 'endAction');

      if (endAction) {
        endAction();
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this._addListener();
    },

    willDestroyElement() {
      this._super(...arguments);

      this._removeListener();
    },

    _addListener() {
      if (Ember.get(this, 'canUseIntersectionObserver')) {
        this._addIntersectionObserver();
      } else {
        this._addScrollListener();

        this._checkScrollPositionTask.perform();
      }
    },

    _removeListener() {
      if (Ember.get(this, 'canUseIntersectionObserver')) {
        this._teardownIntersectionObserver();
      } else {
        this._removeScrollListener();
      }
    },

    /**
     * Create an intersection observer to check if the element is in the view.
     *
     * @method _showIsInView
     * @private
     */
    _addIntersectionObserver() {
      let body = Ember.get(this, '_scrollContainer');
      let el = this.element;
      let threshold = Ember.get(this, 'threshold'); // IntersectionObserver expects null instead of window

      if (body === window) {
        body = null;
      }

      let options = {
        root: body
      };

      if (threshold) {
        options.threshold = threshold;
      } // We only want to send the endAction if it was previously shown


      let _wasEverActive = false;
      let intersectionObserver = new IntersectionObserver(entries => {
        entries.forEach(options => {
          let isIntersecting = options.isIntersecting;

          if (isIntersecting) {
            _wasEverActive = true;

            this._sendAction();
          } else if (_wasEverActive) {
            this._sendEndAction();
          }
        });
      }, options);
      intersectionObserver.observe(el);
      Ember.set(this, 'intersectionObserver', intersectionObserver);
    },

    /**
     * Teardown the intersection observer (if one was created).
     *
     * @method _teardownIntersectionObserver
     * @private
     */
    _teardownIntersectionObserver() {
      let intersectionObserver = Ember.get(this, 'intersectionObserver');

      if (intersectionObserver) {
        intersectionObserver.disconnect();
      }
    },

    /**
     * Add an event listener for the scroll event.
     *
     * @method _addScrollListener
     * @private
     */
    _addScrollListener() {
      let window = Ember.get(this, '_scrollContainer');
      (0, _eventListener.addEventListener)(window, 'scroll', this.elementId, () => {
        this._checkScrollPositionTask.perform();
      });
    },

    /**
     * Remove the event listener for the scroll event.
     *
     * @method _removeScrollListener
     * @private
     */
    _removeScrollListener() {
      let window = Ember.get(this, '_scrollContainer');
      (0, _eventListener.removeEventListener)(window, 'scroll', this.elementId);
    }

  });

  _exports.default = _default;
});