define("ember-cropster-common/components/c-checkbox/component", ["exports", "ember-cropster-common/components/c-checkbox/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    checked: undefined,
    label: undefined,
    id: undefined,
    labelClass: undefined,
    onUpdate: undefined,

    init() {
      this._super(...arguments);

      if (!this.id) {
        Ember.set(this, 'id', `${Ember.guidFor(this)}-checkbox`);
      }

      (false && !(this.onUpdate) && Ember.assert('You must specify an `onUpdate` action', this.onUpdate));
    },

    actions: {
      onUpdate() {
        return this.onUpdate(...arguments);
      }

    }
  });

  _exports.default = _default;
});