define('ember-keen/services/keen', ['exports', 'ember-get-config', 'ember-keen/utils/performance-now', 'ember-keen/utils/merge-deep'], function (exports, _emberGetConfig, _performanceNow, _mergeDeep) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    keenFetch: Ember.inject.service(),
    router: Ember.inject.service(),

    /**
     * The base URL of the Keen API.
     *
     * @property baseUrl
     * @type {String}
     * @public
     */
    baseUrl: 'https://api.keen.io/3.0/projects',

    /**
     * The time in ms to wait until the queue should be sent.
     * Basically, the app will wait until this time passes (via debounce),
     * and send all data captured so far at the same time.
     * This helps to save unecessary requests.
     *
     * @property queueTime
     * @type {Number}
     * @default 5000
     * @public
     */
    queueTime: 5000,

    /**
     * The project ID is taken from KEEN_PROJECT_ID in your config/environment.js
     *
     * @property projectId
     * @type {String}
     * @private
     * @readOnly
     */
    projectId: Ember.computed(function () {
      return Ember.get(_emberGetConfig.default, 'keen.projectId') || Ember.get(_emberGetConfig.default, 'KEEN_PROJECT_ID');
    }),

    /**
     * The write key is taken from KEEN_WRITE_KEY in your config/environment.js
     *
     * @property writeKey
     * @type {String}
     * @private
     * @readOnly
     */
    writeKey: Ember.computed(function () {
      return Ember.get(_emberGetConfig.default, 'keen.writeKey') || Ember.get(_emberGetConfig.default, 'KEEN_WRITE_KEY');
    }),

    /**
     * If writing to keen is possible.
     * Is false if either projectId or writeKey are not set.
     *
     * @property canWrite
     * @type {Boolean}
     * @readOnly
     * @public
     */
    canWrite: Ember.computed.and('projectId', 'writeKey'),

    /**
     * The read key is taken from KEEN_READ_KEY in your config/environment.js
     *
     * @property readKey
     * @type {String}
     * @private
     * @readOnly
     */
    readKey: Ember.computed(function () {
      return Ember.get(_emberGetConfig.default, 'keen.readKey') || Ember.get(_emberGetConfig.default, 'KEEN_READ_KEY');
    }),

    /**
     * If reading from keen is possible.
     * Is false if either projectId or readKey are not set.
     *
     * @property canWrite
     * @type {Boolean}
     * @readOnly
     * @public
     */
    canRead: Ember.computed.and('projectId', 'readKey'),

    /**
     * The queue of events that should be sent.
     *
     * @property _eventQueue
     * @type {Object}
     * @private
     */
    _eventQueue: Ember.computed(function () {
      return {};
    }),

    /**
     * If requests should be logged to the console.
     * This is true if environment is development & keen.logRequests = true in config/environment.js
     *
     * @property _shouldLogRequests
     * @type {Boolean}
     * @readOnly
     * @private
     */
    _shouldLogRequests: Ember.computed(function () {
      return Ember.get(_emberGetConfig.default, 'environment') === 'development' && Ember.get(_emberGetConfig.default, 'keen.logRequests');
    }),

    /**
     * Overwrite this to add commonly used data to all events.
     * The content of this will always be merged into the data sent to the event.
     * This can be used for things like user id, ...
     *
     * @property mergeData
     * @type {Object}
     * @public
     */
    mergeData: Ember.computed(function () {
      return {};
    }),

    /**
     * A property that can be used for performance tracking.
     * This is used by the keen-track-pageview mixin to get the load times of pages.
     *
     * @property _performanceTrack
     * @type {Object}
     * @protected
     */
    _performanceTrack: Ember.computed(function () {
      return {};
    }),

    /**
     * If the automatic page view tracking has been activated.
     *
     * @property  _isTrackingPageViews
     * @type {Boolean}
     * @default false
     * @protected
     */
    _isTrackingPageViews: false,

    /**
     * Actually send an event to Keen.IO.
     * See https://keen.io/docs/data-collection/
     *
     * @method sendEvent
     * @param {String} event The name of the event collection
     * @param {Object} data JSON data to send together with the event
     * @returns {Boolean} True if the event was sent/queued, otherwise false
     * @public
     */
    sendEvent(event, data = {}) {
      let parsedData = this._prepareEventData(data);
      this._logRequest(event, parsedData);

      if (!Ember.get(this, 'canWrite')) {
        return false;
      }

      let queue = Ember.get(this, '_eventQueue');
      if (Ember.get(queue, event)) {
        Ember.get(queue, event).push(parsedData);
      } else {
        queue[event] = [parsedData];
      }
      Ember.run.debounce(this, this._processQueue, Ember.get(this, 'queueTime'));
      return true;
    },

    /**
     * Send an event immediately & return a promise.
     *
     * @method sendEventImmediately
     * @param {String} event The name of the event collection
     * @param {Object} data JSON data to send together with the event
     * @return {RSVP.Promise}
     * @public
     */
    sendEventImmediately(event, data = {}) {
      let parsedData = this._prepareEventData(data);
      this._logRequest(event, parsedData);

      if (!Ember.get(this, 'canWrite')) {
        return Ember.RSVP.Promise.reject('You don\'t have write access to Keen.IO.');
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        this._sendEvent(event, parsedData).then(resolve, reject);
      });
    },

    /**
     * Start tracking all page views.
     * This relies on new features of the router service added in ember-source@3.6, and makes it easy to
     * capture all page views automatically.
     *
     * Usually, you'll want to call this in your application-route's `init()` hook, or similar.
     *
     * @method trackAllPageViews
     * @public
     */
    trackAllPageViews() {
      (false && !(Ember.get(this, 'router.on')) && Ember.assert('You can only use `trackAllPageViews()` on ember-source >= 3.6', Ember.get(this, 'router.on')));
      (false && !(!Ember.get(this, '_isTrackingPageViews')) && Ember.assert('You should only call `trackAllPageViews()` once.', !Ember.get(this, '_isTrackingPageViews')));


      Ember.set(this, '_isTrackingPageViews', true);

      this.router.on('routeWillChange', () => {
        this.startPerformanceTrack('page-view');
      });

      this.router.on('routeDidChange', transition => {
        let toRouteInfo = transition.to;

        let modelLoadTime = this.endPerformanceTrack('page-view') || 0;

        this.startPerformanceTrack('page-view-render-time');

        Ember.run.schedule('afterRender', () => {
          let renderTime = this.endPerformanceTrack('page-view-render-time') || 0;

          let routeName = toRouteInfo.name,
              queryParams = toRouteInfo.queryParams;


          this._trackPageView({ routeName, queryParams, renderTime, modelLoadTime });
        });
      });
    },

    /**
     * Send multiple events at once.
     * Data has to be an object where each key is a collection, containing an array of data objects to send.
     * See https://keen.io/docs/data-collection/ --> Record multiple events
     *
     * @method sendEvents
     * @param {Object} data The data to send
     * @return {Boolean} Returns true if the events were sent, otherwise false
     * @public
     */
    sendEvents(data) {
      this._logRequest(null, data);
      if (!Ember.get(this, 'canWrite')) {
        return false;
      }
      this._sendEvents(data);
      return true;
    },

    /**
     * Make a query to the Keen-API.
     *
     * @method query
     * @param {String} action The query action to perform, e.g. count or sum
     * @param {String} event The event collection to query from
     * @param {Object} data Additional data for the query, e.g. timeframe
     * @return {Ember.RSVP.Promise}
     * @public
     */
    query(action = 'count', event = null, data = {}) {
      if (!Ember.get(this, 'canRead')) {
        return false;
      }

      let url = this._buildReadUrl(action);
      if (event) {
        /* eslint-disable camelcase */
        data.event_collection = event;
        /* eslint-enable camelcase */
      }

      if (!Ember.get(data, 'timeframe')) {
        data.timeframe = 'this_1_month';
      }

      let ajax = this._get(url, data);

      /* eslint-disable no-console */
      return new Ember.RSVP.Promise((resolve, reject) => {
        ajax.then(d => {
          if (d && Ember.get(d, 'result')) {
            return resolve(d);
          } else {
            console.error(d);
            return reject(new Error('Error when querying Keen.'));
          }
        }, e => {
          if (e.responseJSON) {
            e = e.responseJSON;
          }
          console.error(e);
          return reject(new Error('Error when querying Keen.'));
        });
      });
      /* eslint-enable no-console */
    },

    /**
     * Start tracking time for a performance check.
     * You can pass in a key to track multiple times at once.
     * Use the same key for this.endPerformanceTrack to get the corresponding time and re-set the timer.
     *
     * @method startPerformanceTrack
     * @param {string} trackKey
     * @return {Number}
     * @public
     */
    startPerformanceTrack(trackKey = 'general') {
      let start = (0, _performanceNow.default)();

      let performanceTrack = Ember.get(this, '_performanceTrack');
      if (performanceTrack[trackKey]) {
        return;
      }

      performanceTrack[trackKey] = start;
      return start;
    },

    /**
     * Stop tracking time for a performance check.
     * Use the same key as you used for this.startPerformanceTrack.
     * The timer will automatically be cleared for this key.
     *
     * @method endPerformanceTrack
     * @param {string} trackKey
     * @return {Number}
     * @public
     */
    endPerformanceTrack(trackKey = 'general') {
      let performanceTrack = Ember.get(this, '_performanceTrack');
      let start = performanceTrack[trackKey];
      let end = (0, _performanceNow.default)();

      this._clearPerformanceTrack(trackKey);

      if (!start) {
        return null;
      }
      return end - start;
    },

    /**
     * If performance is currently being tracked for a given key.
     *
     * @method isPerformanceTracking
     * @param {String} trackKey
     * @return {Boolean}
     * @public
     */
    isPerformanceTracking(trackKey = 'general') {
      let performanceTrack = Ember.get(this, '_performanceTrack');
      return !!performanceTrack[trackKey];
    },

    _prepareEventData(data) {
      let mergeData = Ember.get(this, 'mergeData') || {};
      let baseData = {
        keen: {
          timestamp: new Date()
        }
      };
      return (0, _mergeDeep.default)(baseData, data, mergeData);
    },

    /**
     * Clear a tracking start time.
     * You will usually not need to call this manually, as it's automatically be called by endPerformanceTrack
     *
     * @method _clearPerformanceTrack
     * @param {string} trackKey
     * @protected
     */
    _clearPerformanceTrack(trackKey = 'general') {
      let performanceTrack = Ember.get(this, '_performanceTrack');
      delete performanceTrack[trackKey];
    },

    /**
     * Process the queue and send all queued events to Keen.IO.
     *
     * @method _processQueue
     * @private
     */
    _processQueue() {
      let queue = Ember.get(this, '_eventQueue');
      this._sendEvents(queue);
      Ember.set(this, '_eventQueue', {});
    },

    /**
     * Send a single event to Keen.IO.
     *
     * @param {String} event The name of the event collection
     * @param {Object} data The data to send to the collection
     * @returns {PromiseObject} A promise which resolves with the response JSON
     * @private
     */
    _sendEvent(event, eventData = {}) {
      let url = this._buildWriteUrl(event);
      return this._post(url, eventData);
    },

    /**
     * Send multiple events to Keen.IO.
     *
     * @param {Object} data The data to send, where each object key is a collection containing an array of data.
     * @returns {PromiseObject} A promise which resolves with the response JSON
     * @private
     */
    _sendEvents(eventData) {
      let url = this._buildWriteUrl(null);
      return this._post(url, eventData);
    },

    /**
     * Send the data to the API.
     *
     * @method _post
     * @param {String} url The URL to post to.
     * @param {Object} data The data which should be sent to the API.
     * @returns {Ember.RSVP.Promise}
     * @private
     */
    _post(url, data) {
      return this._makeRequest(url, data, Ember.get(this, 'writeKey'), {});
    },

    /**
     * Read data from the API.
     *
     * @method _get
     * @param {String} url The URL to get from.
     * @param {Object} [data={}] Optional query configuration for API.
     * @returns {Ember.RSVP.Promise}
     * @private
     */
    _get(url, data = {}) {
      return this._makeRequest(url, data, Ember.get(this, 'readKey'), {});
    },

    /**
     * Primitive method for performing ajax POST requests.
     * This calls `keenFetch.makeRequest` by default.
     *
     * @method _makeRequest
     * @param {String} url The URL to send POST to.
     * @param {Object} [data={}] Custom request data.
     * @param {String} apiKey The API key to use for authentication
     * @param {Object} [extraOptions={}] Custom request options.
     * @returns {Ember.RSVP.Promise}
     * @private
     */
    _makeRequest() {
      return Ember.get(this, 'keenFetch').makeRequest(...arguments);
    },

    /**
     * Log a request to the console.
     * A request will only be logged if environment === development & keen.logRequests = true in config/environment.js
     *
     * @method _logRequest
     * @param {String} event The event name to log, or null if multiple events are logged
     * @param {Object} data
     * @private
     */
    _logRequest(event, data) {
      if (Ember.get(this, '_shouldLogRequests')) {
        console.info('Keen Track:', event, data); // eslint-disable-line
      }
    },

    /**
     * Build the Keen-URL to post to.
     *
     * @param {String} event If set, post to a single event collection, otherwise post to the general collection
     * @returns {String} The built URL
     * @private
     */
    _buildWriteUrl(event) {
      var _EmberGetProperties = Ember.getProperties(this, 'projectId', 'baseUrl');

      let projectId = _EmberGetProperties.projectId,
          baseUrl = _EmberGetProperties.baseUrl;

      if (!event) {
        return `${baseUrl}/${projectId}/events`;
      }
      return `${baseUrl}/${projectId}/events/${event}`;
    },

    /**
     * Build the Keen-URL to read from.
     *
     * @method _buildReadURL
     * @param {String} action The action to read, e.g. 'count' or 'sum'
     * @return {String}
     * @private
     */
    _buildReadUrl(action = 'count') {
      var _EmberGetProperties2 = Ember.getProperties(this, 'projectId', 'baseUrl');

      let projectId = _EmberGetProperties2.projectId,
          baseUrl = _EmberGetProperties2.baseUrl;

      return `${baseUrl}/${projectId}/queries/${action}`;
    },

    _trackPageView({ routeName, queryParams, renderTime = 0, modelLoadTime = 0 }) {
      let totalTime = renderTime + modelLoadTime;

      /* eslint-disable camelcase */
      let keenData = {
        page: routeName,
        query_params: queryParams,
        previous_page: this._previousPage,
        performance: {
          total_time: totalTime,
          model_load_time: modelLoadTime,
          render_time: renderTime,
          total_time_seconds: (totalTime / 1000).toFixed(2) * 1,
          model_load_time_seconds: (modelLoadTime / 1000).toFixed(2) * 1,
          render_time_seconds: (renderTime / 1000).toFixed(2) * 1
        }
      };

      Ember.set(this, '_previousPage', {
        page: keenData.page,
        query_params: keenData.query_params
      });
      /* eslint-enable camelcase */

      this.sendEvent('page-view', keenData);
    }

  });
});