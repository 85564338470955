define("ember-cropster-common/components/c-button/component", ["exports", "ember-cropster-common/components/c-button/template", "ember-concurrency"], function (_exports, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A simple button with an optional loading state.
   *
   * You may also pass attributes like name, disabled, width, class or id to the component.
   *
   * @namespace Component
   * @class CButton
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{#c-button action=(route-action 'dummyAction')}}
   *   My Button Text
   * {{/c-button}}
   * ```
   * @example
   * ```handlebars
   * {{#c-button action=(route-action 'dummyAction') isLoading=true}}
   *   Save
   * {{/c-button}}
   * ```
   * @example
   * ```handlebars
   * {{#c-button action=(route-action 'dummyAction') disabled=true}}
   *   Disabled Button
   * {{/c-button}}
   * ```
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'button',
    attributeBindings: ['name', 'disabled', 'height', 'name', 'type', 'width', 'tooltip:data-tooltip', 'data-test'],
    classNames: ['button'],
    classNameBindings: ['isLoading:button--loading', 'autoLoading:button--loading', 'isHidden:is-hidden', 'showIf::is-hidden', 'tooltip:button--tooltip'],
    // ---------------------------------------------------------------------------------------------------------
    // Attributes

    /**
     * If the button should be disabled.
     *
     * @attribute disabled
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,

    /**
     * An optional type for this button. Set this to 'button' to not trigger form submits.
     *
     * @attribute type
     * @type {String}
     * @optional
     * @public
     */
    type: null,

    /**
     * If this is set to false, do not show the button.
     *
     * @attribute showIf
     * @type {Boolean}
     * @public
     */
    showIf: true,

    /**
     * If this is set, show a tooltip on hover.
     *
     * @attribute tooltip
     * @type {String}
     * @public
     */
    tooltip: null,

    /**
     * If true, a loading spinner is shown and the button cannot be clicked.
     *
     * @attribute isLoading
     * @type {Boolean}
     * @default false
     * @public
     */
    isLoading: false,

    /**
     * If set, this is sent as parameter to the action when clicked.
     *
     * @attribute actionParam
     * @type {Mixed}
     * @public
     */
    actionParam: null,

    /**
     * This action is called when the button is clicked and it is neither disabled not loading.
     *
     * @event onClick
     * @public
     */
    onClick: null,
    action: null,
    _onClick: Ember.computed('onClick', 'action', function () {
      return this.onClick || this.action;
    }),
    // ---------------------------------------------------------------------------------------------------------
    // Properties
    autoLoading: Ember.computed.alias('sendActionTask.isRunning'),

    // ---------------------------------------------------------------------------------------------------------
    // Actions
    click() {
      if (!Ember.get(this, 'disabled') && !Ember.get(this, 'isLoading') && !Ember.get(this, 'autoLoading')) {
        Ember.get(this, 'sendActionTask').perform();
        return true;
      }

      return false;
    },

    // ---------------------------------------------------------------------------------------------------------
    // Methods
    sendActionTask: (0, _emberConcurrency.task)(function* () {
      let _onClick = this._onClick,
          actionParam = this.actionParam;

      if (!_onClick) {
        return;
      }

      return actionParam ? yield _onClick(actionParam) : yield _onClick();
    })
  });

  _exports.default = _default;
});