define("ember-cropster-common/helpers/in-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inArray = inArray;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function inArray(value, array) {
    if (Ember.typeOf(array) !== 'array') {
      return false;
    }

    return array.indexOf(value) > -1;
  }
  /**
   * A helper to check if a given value is in a given array.
   *
   * @class InArray
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{in-array 1 (to-array 1 2)}}
   * ```
   */


  var _default = Ember.Helper.extend({
    _array: null,

    compute(_ref) {
      let _ref2 = _slicedToArray(_ref, 2),
          value = _ref2[0],
          array = _ref2[1];

      this.setupRecompute(array);
      return inArray(value, array);
    },

    destroy() {
      if (this.teardown) {
        this.teardown();
      }

      this._super(...arguments);
    },

    setupRecompute(array) {
      if (this.teardown) {
        this.teardown();
      }

      Ember.set(this, '_array', array);
      this.addObserver('_array.[]', this, this.recompute);

      this.teardown = () => {
        this.removeObserver('_array.[]', this, this.recompute);
      };
    }

  });

  _exports.default = _default;
});