define("ember-cropster-form/components/c-form/group/component", ["exports", "ember-cropster-form/components/c-form/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is a group that can be used to specify sub-models inside of a form.
   *
   * @namespace Component.CForm
   * @class Group
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{#c-form
   *  model=model
   *  onSubmit=(route-action 'onSubmit')
   * as |f|}}
   *
   *   {{#each model.categories as |category|}}
   *      {{#f.group
   *        model=category
   *      as |formGroup|}}
   *        {{formGroup.text field='name' label='Category name'}}
   *      {{/f.group}}
   *   {{/each}}
   *
   *   <button>Submit</button>
   *
   * {{/c-form}}
   * ```
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    /**
     * The model to use for the form group.
     * It works especially well with Ember Data models, but you can also pass in a POJO or any other object.
     *
     * @attribute model
     * @type {Object|Instance}
     * @public
     */
    model: null,

    init() {
      this._super(...arguments);

      (false && !(this.model) && Ember.assert('model needs to be set on c-form/group', this.model));
    }

  });

  _exports.default = _default;
});