define("ember-cropster-form/components/c-form/field/textarea/component", ["exports", "ember-cropster-form/components/c-form/field/component", "ember-cropster-form/components/c-form/field/textarea/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A text area.
   *
   * @namespace Component.CForm.Field
   * @class Textarea
   * @extends Component.CForm.Field
   * @public
   * @example
   * ```handlebars
   * {{c-form/field/textarea
   *   field='name'
   *   value='John'
   *   onChange=(action 'onChange')
   * }}
   * ```
   */
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: '',
    // Has basic fields from field

    /**
     * The inputOptions can contain these fields:
     *
     * * `placeholder`
     * * `rows`
     * * `autoresize`
     * * `autocomplete`
     * * `disableAutocomplete`
     *
     * @attribute inputOptions
     * @type {Object}
     * @public
     */
    rows: undefined,
    autoresize: undefined,
    placeholder: undefined,
    autocomplete: undefined,
    disableAutocomplete: undefined,

    didReceiveAttrs() {
      this._super(...arguments);

      this._parseOptions();
    },

    _parseOptions() {
      let options = this.inputOptions || {};
      let rows = options.rows,
          autoresize = options.autoresize,
          placeholder = options.placeholder,
          autocomplete = options.autocomplete,
          name = options.name,
          disableAutocomplete = options.disableAutocomplete; // Sadly, disabling autofill is not as easy as you'd hope in Chrome
      // So we need to do some trickery...
      // autocomplete="off", which SHOULD do that, is simply ignored by Chrome
      // So we need to instead set it to any invalid string

      autocomplete = disableAutocomplete ? 'falsy-invalid' : autocomplete; // We also need to ensure it has a random name that changes every time,
      // otherwise it will show previously entered values for the field...

      name = disableAutocomplete ? `${this.fieldId}-${Math.round(Math.random() * 100000000)}` : name;
      Ember.set(this, 'rows', rows);
      Ember.set(this, 'autoresize', autoresize);
      Ember.set(this, 'placeholder', placeholder);
      Ember.set(this, 'autocomplete', autocomplete);
      Ember.set(this, 'name', name);
    }

  });

  _exports.default = _default;
});