define("ember-cropster-form/components/c-form/field-wrapper/component", ["exports", "ember-cropster-form/components/c-form/field-wrapper/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is the component that renders an actual field.
   * It renders a label, an input, and validations, plus optionally a message.
   *
   * @namespace Component.CForm
   * @class FieldWrapper
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{c-form/field-wrapper
   *   model=model
   *   field='name'
   *   label='Name'
   * }}
   * ```
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['input__wrapper', 'c-form__field'],
    // Attributes

    /**
     * The field name for this field.
     * Should map to a field on the model.
     *
     * @attribute field
     * @type {String}
     * @public
     */
    field: undefined,

    /**
     * A label to display.
     * If this is not set, no label will be displayed.
     * You should usually always set a label for each input!
     *
     * @attribute label
     * @type {String}
     * @public
     */
    label: undefined,

    /**
     * The current value for this input.
     * Unless specified, it will use the value of the field on the model.
     *
     * @attribute value
     * @type {Mixed}
     * @optional
     * @public
     */
    value: undefined,

    /**
     * The validations object to use for this field.
     * Unless specified, it will use the validations of the field from the model.
     *
     * @attribute validations
     * @type {Object}
     * @optional
     * @public
     */
    validations: undefined,

    /**
     * If set, this message will be displayed between the label & the input.
     *
     * @attribute message
     * @type {String}
     * @optional
     * @public
     */
    message: undefined,

    /**
     * If the field is required.
     *
     * @attribute required
     * @type {Boolean}
     * @optional
     * @public
     */
    required: undefined,

    /**
     * If the field is disabled.
     *
     * @attribute disabled
     * @type {Boolean}
     * @optional
     * @public
     */
    disabled: undefined,

    /**
     * Additional options for the input component.
     * These are specific to the type of input being used.
     *
     * @attribute inputOptions
     * @type {Object}
     * @optional
     * @public
     */
    inputOptions: undefined,

    /**
     * A class to use for the input.
     *
     * @attribute inputClass
     * @type {String}
     * @default 'full-width'
     * @public
     */
    inputClass: 'full-width',

    /**
     * A class to use for the label.
     *
     * @attribute labelClass
     * @type {String}
     * @default 'label--block'
     * @public
     */
    labelClass: 'label--block',

    /**
     * If validation errors should not be shown if the value is null or undefined.
     *
     * @attribute hideErrorsIfEmpty
     * @type {Boolean}
     * @default true
     * @public
     */
    hideErrorsIfEmpty: true,

    /**
     * The action to call when the value is updated.
     * If not set, it will automatically mutate the field on the model.
     *
     * @event onChange
     * @param {Mixed} value
     * @optional
     * @public
     */
    onChange: null,

    /**
     * The component to use for the label.
     *
     * @attribute labelComponent
     * @type {String}
     * @default 'c-form/field-wrapper/label'
     * @public
     */
    labelComponent: 'c-form/field-wrapper/label',

    /**
     * The component to use for the input.
     *
     * @attribute inputComponent
     * @type {String}
     * @default 'c-form/field'
     * @public
     */
    inputComponent: 'c-form/field',

    /**
     * The component to use for the validation.
     *
     * @attribute validationComponent
     * @type {String}
     * @default 'c-form/field-wrapper/validation'
     * @public
     */
    validationComponent: 'c-form/field-wrapper/validation',
    // These are auto-set from c-form

    /**
     * The model of the current form.
     *
     * @attribute model
     * @type {Object}
     * @public
     */
    model: null,

    /**
     * If errors should be shown, no matter if the field is empty or not.
     *
     * @attribute forceShowErrors
     * @type {Boolean}
     * @default false
     * @public
     */
    forceShowErrors: false,
    // Private
    fieldId: Ember.computed('elementId', function () {
      return `${this.elementId}-field`;
    }),
    hasContent: Ember.computed('value', function () {
      let value = this.value; // For arrays, we allow an empty array (as that is the initial state)

      if (Ember.typeOf(value) === 'array' || Ember.typeOf(value) === 'instance' && value.toArray) {
        return value.length > 0;
      }

      return !Ember.isNone(value);
    }),
    hasError: Ember.computed.reads('validations.isInvalid'),
    showError: Ember.computed('hasContent', 'hasError', 'forceShowErrors', 'hideErrorsIfEmpty', function () {
      return this.hasError && (this.hasContent || this.forceShowErrors || !this.hideErrorsIfEmpty);
    }),

    init() {
      this._super(...arguments);

      this._initFields();
    },

    _initFields() {
      if (!this.field) {
        return;
      }

      if (typeof this.value === 'undefined') {
        Ember.defineProperty(this, 'value', Ember.computed.reads(`model.${this.field}`));
      }

      if (typeof this.validations === 'undefined') {
        Ember.defineProperty(this, 'validations', Ember.computed.reads(`model.validations.attrs.${this.field}`));
      }

      if (!this.onChange) {
        this.onChange = val => {
          Ember.set(this.model, this.field, val);
        };
      }
    }

  });

  _exports.default = _default;
});