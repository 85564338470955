define('ember-service-worker-update-notify/utils/service-worker-has-update', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = serviceWorkerHasUpdate;
  const resolve = Ember.RSVP.resolve;
  function serviceWorkerHasUpdate() {
    return resolve('hasServiceWorkerUpdate' in window ? window.hasServiceWorkerUpdate : false);
  }
});