define("ember-service-worker-update-notify/templates/components/service-worker-update-notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9B53QNrt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"hasUpdate\"]]],null,{\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"service-worker-update-notify\"],[11,\"href\",\"\"],[9],[0,\"A new version is available, click here to update.\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-service-worker-update-notify/templates/components/service-worker-update-notify.hbs"
    }
  });

  _exports.default = _default;
});