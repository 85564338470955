define("ember-cropster-common/components/c-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fOFHR2fC",
    "block": "{\"symbols\":[\"data\",\"&default\"],\"statements\":[[4,\"let\",[[27,\"hash\",null,[[\"header\",\"content\"],[[27,\"component\",[\"c-box/-blank\"],[[\"data-test-cbox-title\",\"class\"],[true,[27,\"class-names\",[\"padding-all-2\",\"border-bottom\",\"border-color-grey-3\",\"bold\",[27,\"if\",[[23,[\"isTypeBasic\"]],\"bg-color-grey-4\"],null],[27,\"if\",[[23,[\"isTypeHighlight\"]],\"bg-color-grey-2\"],null],[27,\"if\",[[23,[\"isTypePrimary\"]],\"color-white\"],null],[27,\"if\",[[23,[\"isTypePrimary\"]],\"bg-color-primary\"],null],[27,\"if\",[[23,[\"isTypeWhite\"]],\"bg-color-white\"],null]],null]]]],[27,\"component\",[\"c-box/-blank\"],[[\"data-test-cbox-content\",\"class\"],[true,\"padding-all-2\"]]]]]]],null,{\"statements\":[[0,\"\\n  \"],[14,2,[[22,1,[]]]],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/c-box/template.hbs"
    }
  });

  _exports.default = _default;
});