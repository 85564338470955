define("ember-cropster-common/components/loading-spinner/component", ["exports", "ember-cropster-common/components/loading-spinner/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A simple component to display a loading spinner.
   *
   * @namespace Component
   * @class LoadingSpinner
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{loading-spinner}}
   * ```
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ['inline:display-inline-block:text-center', 'inline::padding'],

    /**
     * If this is true, a compact loading spinner will be displayed.
     *
     * @attribute isCompact
     * @type {Boolean}
     * @default false
     * @public
     */
    isCompact: false,

    /**
     * If true, do not wrap in padding and display inline-block.
     *
     * @attribute inline
     * @type {Boolean}
     * @default {false}
     * @public
     */
    inline: false,

    /**
     * Other classes to add to the loading spinner itself.
     *
     * @attribute loadingSpinnerClass
     * @type {String}
     * @default ''
     * @public
     */
    loadingSpinnerClass: ''
  });

  _exports.default = _default;
});