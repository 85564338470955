define('ember-service-worker-update-notify/components/service-worker-update-notify', ['exports', 'ember-concurrency', 'ember-service-worker-update-notify/templates/components/service-worker-update-notify', 'ember-service-worker-update-notify/utils/service-worker-has-update'], function (exports, _emberConcurrency, _serviceWorkerUpdateNotify, _serviceWorkerHasUpdate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _serviceWorkerUpdateNotify.default,
    pollingInterval: 1200000, // 20 minutes in ms

    tagName: '',

    hasUpdate: false,

    /**
     * Delay attaching the updateHandler to prevent users from
     * seeing a new build notification immediately on page load.
     */
    setupTask: (0, _emberConcurrency.task)(function* () {
      const hasServiceWorker = 'serviceWorker' in navigator;
      const supportsPromises = 'Promise' in window;

      if (hasServiceWorker && supportsPromises) {
        yield (0, _emberConcurrency.timeout)(this.pollingInterval);
        this._attachUpdateHandler();

        const polling = this.pollingTask.perform();

        this.set('polling', polling);
      }
    }).on('didInsertElement'),

    pollingTask: (0, _emberConcurrency.task)(function* () {
      while (true) {
        const reg = yield navigator.serviceWorker.register('/sw.js', { scope: '/' });

        reg.update();

        yield (0, _emberConcurrency.timeout)(this.pollingInterval);
      }
    }),

    _attachUpdateHandler() {
      (0, _serviceWorkerHasUpdate.default)().then(hasUpdate => {
        if (!Ember.isEmpty(this.polling)) {
          this.polling.cancel();
        }

        this.set('hasUpdate', hasUpdate);
      });
    }
  });
});