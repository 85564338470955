define("ember-cropster-form/components/c-form/field/radio/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "juIOnG/M",
    "block": "{\"symbols\":[\"option\",\"i\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"radioOptions\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[27,\"c-input\",null,[[\"type\",\"id\",\"name\",\"update\",\"value\",\"checked\",\"class\",\"disabled\",\"data-test-form-field\"],[\"radio\",[27,\"concat\",[[23,[\"fieldId\"]],\"-\",[22,2,[]]],null],[23,[\"fieldId\"]],[27,\"action\",[[22,0,[]],\"select\"],null],[22,2,[]],[27,\"eq\",[[23,[\"selectedIndex\"]],[22,2,[]]],null],[23,[\"radioInputClass\"]],[23,[\"disabled\"]],[23,[\"field\"]]]]],false],[0,\"\\n\\n  \"],[7,\"label\"],[12,\"for\",[28,[[21,\"fieldId\"],\"-\",[22,2,[]]]]],[12,\"class\",[21,\"radioLabelClass\"]],[9],[0,\"\\n    \"],[14,3,[[22,1,[]]]],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-form/components/c-form/field/radio/template.hbs"
    }
  });

  _exports.default = _default;
});