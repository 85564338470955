define("ember-cropster-form/components/c-form/field/text/component", ["exports", "ember-cropster-form/components/c-form/field/component", "ember-cropster-form/components/c-form/field/text/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A text field.
   *
   * @namespace Component.CForm.Field
   * @class Text
   * @extends Component.CForm.Field
   * @public
   * @example
   * ```handlebars
   * {{c-form/field/text
   *   field='name'
   *   value='John'
   *   onChange=(action 'onChange')
   * }}
   * ```
   * @example
   * ```handlebars
   * {{c-form/field/text
   *   field='email'
   *   value=''
   *   inputOptions=(hash
   *     type='email'
   *     placeholder='test@mail.com'
   *   )
   * }}
   * ```
   */
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: '',
    // Has basic fields from field

    /**
     * The inputOptions can contain these fields:
     *
     * * `type`
     * * `placeholder`
     * * `min`
     * * `max`
     * * `step`
     * * `autocomplete`
     * * `disableAutocomplete`
     *
     * @attribute inputOptions
     * @type {Object}
     * @public
     */
    type: undefined,
    min: undefined,
    max: undefined,
    step: undefined,
    placeholder: undefined,
    autocomplete: undefined,
    disableAutocomplete: undefined,
    // We cache the type here, as it might be toggled for passwords
    isTypePassword: Ember.computed.equal('type', 'password'),
    actualType: null,

    didReceiveAttrs() {
      this._super(...arguments);

      this._parseOptions();
    },

    _parseOptions() {
      let options = this.inputOptions || {};
      let _options$type = options.type,
          type = _options$type === void 0 ? 'text' : _options$type,
          min = options.min,
          max = options.max,
          step = options.step,
          placeholder = options.placeholder,
          autocomplete = options.autocomplete,
          name = options.name,
          disableAutocomplete = options.disableAutocomplete; // Sadly, disabling autofill is not as easy as you'd hope in Chrome
      // So we need to do some trickery...
      // autocomplete="off", which SHOULD do that, is simply ignored by Chrome
      // So we need to instead set it to any invalid string

      autocomplete = disableAutocomplete ? 'falsy-invalid' : autocomplete; // We also need to ensure it has a random name that changes every time,
      // otherwise it will show previously entered values for the field...

      name = disableAutocomplete ? `${this.fieldId}-${Math.round(Math.random() * 100000000)}` : name;
      Ember.set(this, 'type', type);
      Ember.set(this, 'min', min);
      Ember.set(this, 'max', max);
      Ember.set(this, 'step', step);
      Ember.set(this, 'placeholder', placeholder);
      Ember.set(this, 'autocomplete', autocomplete);
      Ember.set(this, 'name', name);

      if (!this.actualType) {
        Ember.set(this, 'actualType', type);
      }
    },

    actions: {
      togglePasswordInput() {
        let type = this.actualType === 'password' ? 'text' : 'password';
        Ember.set(this, 'actualType', type);
        let el = document && this.fieldId ? document.querySelector(`#${this.fieldId}`) : null;

        if (el) {
          el.focus();
        }
      }

    }
  });

  _exports.default = _default;
});