define("ember-indexeddb/serializers/indexed-db", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const JSONAPISerializer = _emberData.default.JSONAPISerializer;
  /**
   *
   * This Ember-Data serializer will fetch and save all data to/from IndexedDB.
   *
   * @module Ember Data
   * @class IndexedDbSerializer
   * @extends DS.JSONAPISerializer
   * @public
   */

  var _default = JSONAPISerializer.extend({});

  _exports.default = _default;
});