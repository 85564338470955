define("ember-cropster-common/components/c-radio-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VatTJWXi",
    "block": "{\"symbols\":[\"opt\",\"i\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"c-input\",null,[[\"type\",\"name\",\"checked\",\"id\",\"value\",\"onUpdate\",\"data-test-radio-button\"],[\"radio\",[23,[\"elementId\"]],[27,\"eq\",[[22,1,[]],[23,[\"value\"]]],null],[27,\"concat\",[[23,[\"elementId\"]],\"-\",[22,2,[]]],null],[22,2,[]],[27,\"action\",[[22,0,[]],\"onUpdate\",[22,1,[]]],null],[22,2,[]]]]],false],[0,\"\\n\\n  \"],[7,\"label\"],[12,\"for\",[28,[[21,\"elementId\"],\"-\",[22,2,[]]]]],[9],[14,3,[[22,1,[]],[27,\"hash\",null,[[\"index\"],[[22,2,[]]]]]]],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/c-radio-buttons/template.hbs"
    }
  });

  _exports.default = _default;
});