define("ember-cropster-common/components/c-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "24IUn9X5",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[7,\"span\"],[12,\"class\",[21,\"wrapperClass\"]],[9],[0,\"\\n  \"],[7,\"label\"],[12,\"for\",[21,\"id\"]],[12,\"class\",[27,\"class-names\",[\"toggle__label\",[23,[\"labelClass\"]]],null]],[9],[1,[21,\"labelOff\"],false],[10],[0,\"\\n\\n  \"],[5,\"c-input\",[[11,\"data-test-checkbox\",\"true\"],[13,1]],[[\"@type\",\"@class\",\"@id\",\"@checked\",\"@onUpdate\"],[\"checkbox\",[27,\"class-names\",[[23,[\"class\"]],\"toggle\"],null],[21,\"id\"],[21,\"checked\"],[27,\"action\",[[22,0,[]],\"onUpdate\"],null]]]],[0,\"\\n  \"],[7,\"label\"],[12,\"for\",[21,\"id\"]],[9],[10],[0,\"\\n\\n  \"],[7,\"label\"],[12,\"for\",[21,\"id\"]],[12,\"class\",[27,\"class-names\",[\"toggle__label\",[23,[\"labelClass\"]]],null]],[9],[1,[21,\"labelOn\"],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-common/components/c-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});