define("ember-cropster-common/components/c-radio-buttons/component", ["exports", "ember-cropster-common/components/c-radio-buttons/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    value: undefined,
    options: undefined,
    onUpdate: undefined,

    init() {
      this._super(...arguments);

      (false && !(this.onUpdate) && Ember.assert('You must specify an `onUpdate` action', this.onUpdate));
      (false && !(this.options) && Ember.assert('You must specify an `options` array', this.options));
    },

    actions: {
      onUpdate(selectedOption) {
        // We don't care about the actual "value" of the radio button, which is just the index
        // We only pass through the selected option itself to keep the API surface slim
        return this.onUpdate(selectedOption);
      }

    }
  });

  _exports.default = _default;
});