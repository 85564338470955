define("ember-component-css/instance-initializers/route-styles", ["exports", "ember-component-css/utils/init-route-styles"], function (_exports, _initRouteStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // This file is removed from the build in Ember < 3.6
  function initialize(appInstance) {
    let router = appInstance.lookup('service:router');
    router.on('routeDidChange', function (transition) {
      (0, _initRouteStyles.default)(appInstance, transition.routeInfos);
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});