define("ember-cropster-common/components/modal-overlay/component", ["exports", "ember-cropster-common/components/modal-overlay/template", "ember-concurrency"], function (_exports, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A modal overlay with yielded content.
   *
   * @namespace Component
   * @class ModalOverlay
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{#if show}}
   *   {{#modal-overlay
   *     title="My Overlay"
   *     close=(action (mut show false))
   *   }}
   *     Modal content goes here!
   *   {{/modal-overlay}}
   * {{/if}}
   * {{#c-button action=(action (mut show)) actionParam=true}}Show{{/c-button}}
   * ```
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    keyboardShortcuts: Ember.inject.service(),

    /**
     * Classes to add to the modal.
     *
     * @attribute modalClasses
     * @type {String}
     * @public
     */
    modalClasses: '',
    isTesting: Ember.computed(function () {
      let config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.environment === 'test';
    }),

    /**
     * Optionally render into another wormhole.
     * This defaults to 'modal-wormhole', and should be the ID of an empty div container to render into.
     *
     * @attribute wormhole
     * @type {String}
     * @default 'modal-wormhole'
     * @public
     */
    wormhole: Ember.computed(function () {
      if (this.isTesting) {
        return document.querySelector('#ember-testing > .ember-view').id;
      }

      return 'modal-wormhole';
    }),

    /**
     * An optional title for the modal.
     *
     * @attribute title
     * @type {String}
     * @public
     */
    title: null,

    /**
     * An action to call when closing the modal.
     * This action is responsible for actually hiding the component!
     *
     * @event close
     * @public
     */
    close: null,

    /**
     * If false, don't show close button & do not enable close on outside click/ESC.
     *
     * @attribute enableAutoClose
     * @type {Boolean}
     * @default true
     * @public
     */
    enableAutoClose: true,

    /**
     * If the modal is currently open.
     * This is only used for animation purposes - actually show/hide the component by including/excluding it from the DOM!
     *
     * @property isOpen
     * @type {Boolean}
     * @private
     */
    isOpen: false,

    /**
     * The actual element in which the modal will be rendered into.
     *
     * @property wormholeElement
     * @type {DOMElement}
     * @readOnly
     * @protected
     */
    wormholeElement: Ember.computed('wormhole', function () {
      let selector = `#${this.wormhole}`;
      return document.querySelector(selector);
    }),

    didInsertElement() {
      this.setupModalTask.perform();
    },

    willDestroyElement() {
      this._super(...arguments);

      this._removeEventListener();
    },

    actions: {
      outsideClick(e) {
        if (!e || !this.enableAutoClose) {
          return null;
        }

        let target = e.target;

        if (target.classList.contains('modal__wrapper')) {
          return this._close();
        }

        return null;
      },

      close() {
        return this._close(...arguments);
      }

    },
    setupModalTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(1);
      Ember.set(this, 'isOpen', true);

      this._addEventListener();
    }),

    _close() {
      return this.closeTask.perform(...arguments);
    },

    closeTask: (0, _emberConcurrency.task)(function* () {
      let isTesting = this.isTesting,
          close = this.close;
      /* istanbul ignore else */

      if (isTesting && close) {
        close(...arguments);
        return;
      }

      Ember.set(this, 'isOpen', false);
      yield (0, _emberConcurrency.timeout)(200);

      if (close) {
        close(...arguments);
      }
    }).drop(),

    _addEventListener() {
      if (!this.enableAutoClose) {
        return;
      }

      let title = this.title;
      let label = 'Close modal overlay';

      if (title) {
        label = `${label}: ${title};`;
      }

      this._keyboardHandler = this.keyboardShortcuts.registerKeyboardShortcut(label, 'escape', () => this._close());
    },

    _removeEventListener() {
      if (!this.enableAutoClose) {
        return;
      }

      let keyboardHandler = this._keyboardHandler;

      if (keyboardHandler) {
        keyboardHandler.unregister();
      }
    }

  });

  _exports.default = _default;
});