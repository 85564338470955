define("ember-cropster-common/objects/keyboard-shortcuts-registry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.KeyboardShortcutsRegistry = void 0;

  /**
   * A registry to keep keyboard shortcuts.
   * This keeps a list of ID+label+callback combinations, and let's you fetch by ID or label.
   * Fetching by ID is optimized (=as fast as possible).
   *
   * @namespace EmberCropsterCommon.Objects
   * @class KeyboardShortcutsRegistry
   * @extends Ember.Object
   * @public
   */
  const KeyboardShortcutsRegistry = Ember.Object.extend({
    _registry: null,

    /**
     * Register a new handler.
     * This will return the handler, which has a `unregister` method
     * which you can call to unregister it from the registry.
     *
     * @method register
     * @param {String} id
     * @param {String} label
     * @param {Function} callback
     * @return {{id: String, label: String, callback: Function, unregister: Function}}
     * @public
     */
    register(id, label, callback) {
      let registry = this._registry;
      let handler = {
        id,
        label,
        callback,

        unregister() {
          registry[id].removeObject(this);
        }

      };

      if (registry[id]) {
        registry[id].unshiftObject(handler);
      } else {
        registry[id] = [handler];
      }

      return handler;
    },

    /**
     * Find the first handler for an ID.
     *
     * @method findFirstById
     * @param {String} id
     * @return {Object}
     * @public
     */
    findFirstById(id) {
      let items = this.findAllById(id);
      return items && items[0];
    },

    /**
     * Find all handlers for a given ID.
     *
     * @method findAllById
     * @param {String} id
     * @return {Array<Object>}
     * @public
     */
    findAllById(id) {
      return this._registry[id];
    },

    init() {
      this._super(...arguments);

      Ember.set(this, '_registry', {});
    }

  });
  _exports.KeyboardShortcutsRegistry = KeyboardShortcutsRegistry;
  var _default = KeyboardShortcutsRegistry;
  _exports.default = _default;
});