define("ember-cropster-common/services/keyboard-shortcuts", ["exports", "ember-cropster-common/utils/dom/event-listener", "ember-cropster-common/objects/keyboard-shortcuts-registry"], function (_exports, _eventListener, _keyboardShortcutsRegistry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Register keyboard shortcuts.
   * They will be triggered when typing the key anywhere on the page.
   *
   * Usage:
   *
   * ```js
   * keyboardShortcuts.registerKeyboardShortcut('close modal', 'escape', () => this._closeModal());
   * ```
   *
   * @namespace Service
   * @class KeyboardShortcuts
   * @extends Ember.Service
   * @public
   */
  var _default = Ember.Service.extend({
    /**
     * An instance of the KeyboardShortcutsRegistry object.
     *
     * @property _registry
     * @type {Object[]}
     * @readOnly
     * @public
     */
    _registry: null,
    _rootElement: Ember.computed(function () {
      return document.body;
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, '_registry', _keyboardShortcutsRegistry.KeyboardShortcutsRegistry.create());

      this._addEventListener();
    },

    willDestroy() {
      this._super(...arguments);

      this._removeEventListener();

      this._registry.destroy();
    },

    /**
     * Register a keyboard shortcut.
     * The label must be a unique, human readable description of the shortcut.
     * It might be displayed to the user! It is also used to remove the shortcut again, if needed.
     *
     * Note that only the last added handler for a certain key combination will be called.
     * E.g. if you add two handlers for `escape`, only the second added one will be triggered.
     * If you remove the second one, and press ESC, it will call to the first one.
     *
     * This will return a handler item, with an `unregister()` method.
     *
     * @method registerKeyboardShortcut
     * @param {String} label A human readable, unique identifier for the shortcut
     * @param {String|Object} keyData Either the key name (e.g. `escape`), or an object: `{ key: 'm', shiftKey: true, ctrlKey: false }`
     * @param {Function} callback The function to call. Receives the event & the handler as arguments
     * @return {Object} The registry item, with an unregister method
     * @public
     */
    registerKeyboardShortcut(label, keyData, callback) {
      if (typeof keyData === 'string') {
        keyData = {
          key: keyData
        };
      }

      (false && !(label) && Ember.assert('keyboardShortcut.registerKeyboardShortcut: label must be set', label));
      (false && !(keyData.key && typeof keyData.key === 'string') && Ember.assert('keyboardShortcut.registerKeyboardShortcut: key must be a string', keyData.key && typeof keyData.key === 'string'));
      (false && !(callback) && Ember.assert('keyboardShortcut.registerKeyboardShortcut: callback must be set', callback));

      let keyGroupId = this._composeKeyGroupId(keyData);

      return this._registry.register(keyGroupId, label, callback);
    },

    _findHandlerForKeyData(keyData) {
      let id = this._composeKeyGroupId(keyData);

      return this._registry.findFirstById(id);
    },

    _addEventListener() {
      (0, _eventListener.addEventListener)(this._rootElement, 'keydown', 'keyboard-shortcut-service', event => this._onKeyDown(event));
    },

    _removeEventListener() {
      (0, _eventListener.removeEventListener)(this._rootElement, 'keydown', 'keyboard-shortcut-service');
    },

    _onKeyDown(event) {
      let shiftKey = event.shiftKey,
          ctrlKey = event.ctrlKey,
          key = event.key;
      let keyData = {
        key,
        shiftKey,
        ctrlKey
      }; // This can happen when triggering custom keyboard events

      if (typeof key !== 'string') {
        return;
      }

      let handler = this._findHandlerForKeyData(keyData);

      if (handler) {
        handler.callback(event, handler);
      }
    },

    _composeKeyGroupId(_ref) {
      let key = _ref.key,
          shiftKey = _ref.shiftKey,
          ctrlKey = _ref.ctrlKey;
      key = key.toLowerCase();
      shiftKey = shiftKey ? 1 : 0;
      ctrlKey = ctrlKey ? 1 : 0;
      return `${key}-${shiftKey}-${ctrlKey}`;
    }

  });

  _exports.default = _default;
});