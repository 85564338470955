define("ember-cropster-common/components/toast-container/message/component", ["exports", "ember-cropster-common/components/toast-container/message/template", "ember-concurrency"], function (_exports, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['toast'],
    classNameBindings: ['toastClass', 'fadeOutAndRemoveToast.isRunning:toast--fade-out', 'waitForRemove.isRunning:toast--with-progress'],
    // Attributes

    /*
     * A toast item looks like this:
     *
     * ```js
     * {
     *  title: 'toast title',
     *  message: 'toast message',
     *  type: 'info',
     *  options: { timeOut: 1000, toastClass: 'additional-class' }
     * }
     * ```
     */
    toastItem: null,
    onRemove: null,
    // Properties
    message: Ember.computed.readOnly('toastItem.message'),
    title: Ember.computed.readOnly('toastItem.title'),
    type: Ember.computed.readOnly('toastItem.type'),
    options: Ember.computed.readOnly('toastItem.options'),
    shouldAutoHide: Ember.computed('timeOut', function () {
      return this.timeOut !== 0;
    }),
    timeOut: Ember.computed('options.timeOut', '_defaultTimeOut', function () {
      let defaultTimeOut = this._defaultTimeOut;
      let timeOut = this.options.timeOut;
      (false && !(['number', 'undefined'].includes(typeof timeOut)) && Ember.assert('timeOut needs to be a number or undefined', ['number', 'undefined'].includes(typeof timeOut)));
      return typeof timeOut === 'number' ? timeOut : defaultTimeOut;
    }),
    progressBarStyle: Ember.computed('timeOut', function () {
      return this.timeOut ? Ember.String.htmlSafe(`animation-duration: ${this.timeOut}ms;`) : null;
    }),
    toastClass: Ember.computed('type', 'options.toastClass', function () {
      return `toast--${this.type} ${this.options.toastClass || ''}`;
    }),
    _defaultTimeOut: Ember.computed('_isTesting', function () {
      return this._isTesting ? 10 : 5000;
    }),
    _fadeOutTimeOut: Ember.computed('_isTesting', function () {
      return this._isTesting ? 1 : 500;
    }),
    _isTesting: Ember.computed(function () {
      let config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.environment === 'test';
    }),

    didInsertElement() {
      this._super(...arguments);

      if (this.shouldAutoHide) {
        this.waitForRemove.perform();
      }
    },

    mouseEnter() {
      this.waitForRemove.cancelAll();
    },

    mouseLeave() {
      if (this.shouldAutoHide && !this.fadeOutAndRemoveToast.isRunning) {
        this.waitForRemove.perform();
      }
    },

    click() {
      this.waitForRemove.cancelAll();

      if (typeof this.options.onClick === 'function') {
        this.options.onClick();
      }

      this.fadeOutAndRemoveToast.perform();
    },

    waitForRemove: (0, _emberConcurrency.task)(function* () {
      let timeOut = this.timeOut;
      yield (0, _emberConcurrency.timeout)(timeOut);
      yield this.fadeOutAndRemoveToast.perform();
    }).restartable(),
    fadeOutAndRemoveToast: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(this._fadeOutTimeOut);
      let toastItem = this.toastItem;
      this.onRemove(toastItem);
    }).restartable()
  });

  _exports.default = _default;
});