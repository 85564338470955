define("ember-cropster-form/components/c-form/field/textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Q4Grzw3",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"c-textarea\",null,[[\"id\",\"update\",\"value\",\"rows\",\"autoresize\",\"disabled\",\"placeholder\",\"class\",\"autocomplete\",\"name\",\"data-test-form-field\"],[[23,[\"fieldId\"]],[23,[\"onChange\"]],[23,[\"value\"]],[23,[\"rows\"]],[23,[\"autoresize\"]],[23,[\"disabled\"]],[23,[\"placeholder\"]],[23,[\"actualInputClass\"]],[23,[\"autocomplete\"]],[23,[\"name\"]],[23,[\"field\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropster-form/components/c-form/field/textarea/template.hbs"
    }
  });

  _exports.default = _default;
});