define("ember-l10n/helpers/pn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * This helper provides contextual plural message, where context has to
   * be given as 4th argument, otherwise just works the same as `n` helper.
   *
   * ```html
   * {{pn '{{count}} apple' '{{count}} apples' someBoundProperty 'context'}}
   * ```
   *
   * @namespace Helper
   * @class PN
   * @extends Ember.Helper
   * @public
   */
  var _default = Ember.Helper.extend({
    l10n: Ember.inject.service(),

    compute(_ref, hash) {
      let _ref2 = _slicedToArray(_ref, 4),
          msgid = _ref2[0],
          msgidPlural = _ref2[1],
          count = _ref2[2],
          msgctxt = _ref2[3];

      let l10n = Ember.get(this, 'l10n');

      if (!msgid) {
        return msgid;
      }

      return l10n.pn(msgid, msgidPlural, count, msgctxt, hash);
    },

    _watchLocale: Ember.observer('l10n.locale', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});