define("ember-cropster-common/utils/dom/element-offset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getElementOffset;

  // This is a replacement for jQuery $el.offset()
  function getElementOffset(element) {
    let de = document.documentElement;
    let box = element.getBoundingClientRect();
    let top = box.top + window.pageYOffset - de.clientTop;
    let left = box.left + window.pageXOffset - de.clientLeft;
    return {
      top,
      left
    };
  }
});