define("ember-cropster-form/components/c-form/component", ["exports", "ember-cropster-form/components/c-form/template", "ember-concurrency"], function (_exports, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The form wrapper component.
   *
   * @namespace Component
   * @class CForm
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{#c-form
   *  model=model
   *  onSubmit=(route-action 'onSubmit')
   * as |f|}}
   *
   *   {{f.text
   *     field='name'
   *     label='Name'
   *   }}
   *
   *   {{f.checkbox
   *     field='isGlobal'
   *     inputOptions=(hash
   *       checkboxLabel='Is Global'
   *     )
   *   }}
   *
   *   {{#f.select
   *     field='country'
   *     label='Country'
   *     inputOptions=(hash
   *       selectOptions=allCountries
   *       searchField='name'
   *     )
   *   as |country|}}
   *     {{country.name}}
   *   {{/f.select}}
   *
   *  {{#f.radio
   *     field='country'
   *     label='Country'
   *     inputOptions=(hash
   *       radioOptions=allCountries
   *     )
   *   as |country|}}
   *     {{country.name}}
   *   {{/f.radio}}
   *
   *   <button>Submit</button>
   *
   * {{/c-form}}
   * ```
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'form',
    attributeBindings: ['autocomplete'],

    /**
     * The model to use for the form.
     * It works especially well with Ember Data models, but you can also pass in a POJO or any other object.
     *
     * @attribute model
     * @type {Object|Instance}
     * @public
     */
    model: null,

    /**
     * The action to call when the form is submitted.
     * Note that this will never be called if the model has a `validations.isInvalid` property.
     *
     * @event onSubmit
     * @param {Object} model
     * @public
     */
    onSubmit: null,
    // Private
    forceShowErrors: false,

    init() {
      this._super(...arguments);

      (false && !(this.model) && Ember.assert('model needs to be set on c-form', this.model));
      (false && !(this.onSubmit) && Ember.assert('onSubmit action needs to be set on c-form', this.onSubmit));
    },

    submit(e) {
      e.preventDefault();
      this.trySubmitTask.perform();
    },

    trySubmitTask: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'forceShowErrors', false);

      if (typeof Ember.get(this, 'model.validate') === 'function') {
        yield this.model.validate();
      }

      if (Ember.get(this, 'model.validations.isInvalid')) {
        Ember.set(this, 'forceShowErrors', true);
        return;
      }

      yield this.onSubmit(this.model);
    }).drop()
  });

  _exports.default = _default;
});