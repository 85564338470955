define("ember-cropster-common/components/dropdown-menu/dropdown-menu-content/component", ["exports", "ember-cropster-common/components/dropdown-menu/dropdown-menu-content/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The content for a dropdown menu.
   * This is used internally by dropdown-menu.
   *
   *
   * @namespace Component
   * @class DropdownMenuContent
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['dropdown-menu'],
    classNameBindings: ['alignLeft:dropdown-menu--left', 'isOpen:dropdown-menu--toggled'],

    /**
     * If true, align the dropdown left instead of right.
     *
     * @attribute alignLeft
     * @type {Boolean}
     * @default false
     * @public
     */
    alignLeft: false,

    /**
     * If true, the menu is shown.
     *
     * @attribute isOpen
     * @type {Boolean}
     * @default false
     * @public
     */
    isOpen: false,

    /**
     * If this is true, close the dropdown after clicking inside.
     *
     * @attribute autoClose
     * @type {Boolean}
     * @default true
     * @public
     */
    autoClose: true,

    click() {
      let action = Ember.get(this, 'closeAction');

      if (Ember.get(this, 'autoClose') && action) {
        action();
      }
    }

  });

  _exports.default = _default;
});