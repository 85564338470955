define('ember-cli-ifa/initializers/asset-map', ['exports', 'ember-cli-ifa/services/asset-map', 'ember-cli-ifa/utils/get-asset-map-data'], function (exports, _assetMap, _getAssetMapData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(app) {
    let assetMapFile = (0, _getAssetMapData.default)();

    // This is split out like this, in order to prevent this from being accidentally replaced
    let replacementPath = ['__', 'asset_map_placeholder', '__'].join('');

    if (!assetMapFile || assetMapFile === replacementPath) {
      app.register('service:asset-map', _assetMap.default);
      return;
    }

    if (Ember.typeOf(assetMapFile) === 'object' && assetMapFile.assets) {
      _assetMap.default.reopen({
        map: assetMapFile.assets,
        prepend: assetMapFile.prepend,
        enabled: true
      });
      app.register('service:asset-map', _assetMap.default);
    } else {
      app.deferReadiness();

      let ajax = Ember.$.ajax;

      const promise = new Ember.RSVP.Promise((resolve, reject) => {
        let options = {
          type: 'GET',
          dataType: 'json',
          success: resolve,
          error: reject
        };
        ajax(assetMapFile, options);
      });

      promise.then((map = {}) => {
        _assetMap.default.reopen({
          map: map.assets,
          prepend: map.prepend,
          enabled: true
        });
      }).then(() => {
        app.register('service:asset-map', _assetMap.default);
        app.advanceReadiness();
      });
    }
  }

  exports.default = {
    name: 'asset-map',
    initialize
  };
});