define("ember-cropster-common/mixins/confirm-leave", ["exports", "ember-cropster-common/utils/dom/event-listener"], function (_exports, _eventListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Whenever user wants to transition from current route or
   * close browser window, this mixin can show a confirmation
   * dialog waiting for legitimation by user clicking "OK".
   * Please note that closing the browser window is a native
   * event from browser and likely to be note influenceable
   * with a custom message, at least in latest versions of
   * Chrome, Firefox and Safari.
   *
   * @namespace EmberCropsterCommon.Mixin
   * @class ConfirmLeave
   * @extends Ember.Mixin
   * @public
   */
  var _default = Ember.Mixin.create({
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Confirmation message to show for users.
     *
     * @public
     * @property confirmationMessage
     * @type String
     */
    confirmationMessage: Ember.computed(function () {
      return 'You have unsaved changes, do you really want to leave the page?';
    }),

    /**
     * Determines whether or not user can leave page
     * without confirming the dialog. By default, the
     * model's `hasDirtyAttribute` property is checked.
     *
     * @public
     * @property canLeavePage
     * @type Boolean
     */
    canLeavePage: Ember.computed.not('controller.model.hasDirtyAttributes'),

    /**
     * Determines whether or not user can leave route,
     * which is an alias for `canLeavePage` by default.
     *
     * @public
     * @property canLeavePage
     * @type Boolean
     */
    canLeaveRoute: Ember.computed.alias('canLeavePage'),

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Creates internal event listener refs.
     *
     * @public
     * @method init
     * @return Void
     */
    init() {
      this._super(...arguments);

      (false && !(false) && Ember.deprecate('The confirm-leave mixin is deprecated and will be removed from ember-cropster-common soon.', false, {
        id: 'ember-cropster-common.mixins.confirm-leave',
        until: '6.0.0'
      }));
      this._onUnloadHandler = this.onUnloadHandler.bind(this);
      this._onBeforeUnloadHandler = this.onBeforeUnloadHandler.bind(this);
    },

    /**
     * Adds event listener to `window`.
     *
     * @public
     * @method activate
     * @return Void
     */
    activate() {
      this._super(...arguments);

      (0, _eventListener.addEventListener)(window, 'unload', `unload-handler.${Ember.get(this, 'routeName')}`, this._onUnloadHandler);
      (0, _eventListener.addEventListener)(window, 'beforeunload', `unload-handler.${Ember.get(this, 'routeName')}`, this._onBeforeUnloadHandler);
    },

    /**
     * Removes event listener from `window`.
     *
     * @public
     * @method deactivate
     * @return Void
     */
    deactivate() {
      this._super(...arguments);

      (0, _eventListener.removeEventListener)(window, 'unload', `unload-handler.${Ember.get(this, 'routeName')}`);
      (0, _eventListener.removeEventListener)(window, 'beforeunload', `unload-handler.${Ember.get(this, 'routeName')}`);
    },

    /**
     * Shows a native browser confirmation per default
     * and returns a promise. Override this method to
     * use a custom confirmation dialog.
     *
     * @public
     * @method confirm
     * @return {RSVP.Promise}
     */
    confirm() {
      let promiseCallback = (resolve, reject) => {
        let message = Ember.get(this, 'confirmationMessage');

        if (!window.confirm(message)) {
          reject();
          return;
        }

        resolve();
      };

      return new Ember.RSVP.Promise(promiseCallback);
    },

    /**
     * Handles the `unload` event of `window`.
     * This event has no functionality by now.
     *
     * @public
     * @method onUnloadHandler
     * @param {Object} event
     * @return {Void}
     */
    onUnloadHandler()
    /* event */
    {},

    /**
     * Handles the `beforeunload` event of `window`.
     * Note that modern browsers doesn't show the
     * message returned, but a predefined one.
     *
     * @public
     * @method onBeforeUnloadHandler
     * @param {Object} event
     * @return {Void|String}
     */
    onBeforeUnloadHandler(event) {
      if (Ember.get(this, 'canLeavePage')) {
        return;
      }

      let message = Ember.get(this, 'confirmationMessage');
      event.returnValue = message;
      return message;
    },

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * This checks page leave for ember transitions.
       * In case user should confirm leave, a dialog
       * containing `confirmationMessage` is shown if
       * `canLeaveRoute` is currently false.
       *
       * @private
       * @event willTransition
       * @param {Object} transition
       * @return {Boolean}
       */
      willTransition(transition) {
        this._super(...arguments);

        if (Ember.get(this, 'canLeaveRoute')) {
          return true;
        }

        let confirmSuccess = () => {// default transition
        };

        let confirmFailure = () => {
          transition.abort();
        };

        this.confirm().then(confirmSuccess, confirmFailure);
        return true;
      }

    }
  });

  _exports.default = _default;
});