define("ember-cropster-common/errors/ajax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AjaxNetworkError = _exports.AjaxError = void 0;

  class AjaxError extends Ember.Error {
    constructor(payload) {
      let _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          status = _ref.status,
          _ref$message = _ref.message,
          message = _ref$message === void 0 ? 'Ajax operation failed' : _ref$message,
          detail = _ref.detail,
          _ref$code = _ref.code,
          code = _ref$code === void 0 ? 'error' : _ref$code;

      super(message);
      this.code = code;
      this.payload = payload;
      this.status = status;
      this.errors = [{
        code,
        detail: detail || message,
        status: `${status}`,
        title: message
      }];
    }

  }

  _exports.AjaxError = AjaxError;

  class AjaxNetworkError extends Ember.Error {
    constructor(payload) {
      let _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref2$status = _ref2.status,
          status = _ref2$status === void 0 ? 0 : _ref2$status,
          _ref2$message = _ref2.message,
          message = _ref2$message === void 0 ? 'Network request failed' : _ref2$message,
          detail = _ref2.detail,
          _ref2$code = _ref2.code,
          code = _ref2$code === void 0 ? 'offline' : _ref2$code;

      super(message);
      this.code = code;
      this.payload = payload;
      this.status = status;
      this.errors = [{
        code,
        detail: detail || message,
        status: `${status}`,
        title: message
      }];
    }

  }

  _exports.AjaxNetworkError = AjaxNetworkError;
});