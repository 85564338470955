define("ember-cropster-form/components/c-form/field/select/component", ["exports", "ember-cropster-form/components/c-form/field/component", "ember-cropster-form/components/c-form/field/select/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A select box.
   *
   * @namespace Component.CForm.Field
   * @class Select
   * @extends Component.CForm.Field
   * @public
   * @example
   * ```handlebars
   * {{#c-form/field/select
   *   field='country'
   *   value='Austria'
   *   inputOptions=(hash
   *     selectOptions=(to-array 'Austria' 'Germany' 'United States')
   *   )
   * as |country}}
   *   {{country}}
   * {{/c-form/field/select}}
   * ```
   * @example
   * ```handlebars
   * {{#c-form/field/select
   *   field='country'
   *   value='Austria'
   *   inputOptions=(hash
   *     selectOptions=(to-array 'Austria' 'Germany' 'United States')
   *     multiple=true
   *   )
   * as |country}}
   *   {{country}}
   * {{/c-form/field/select}}
   * ```
   */
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: '',
    // Has basic fields from field

    /**
     * The inputOptions can contain these fields:
     *
     * * `selectOptions` (required!)
     * * `multiple`
     * * `search`
     * * `searchField`
     * * `placeholder`
     * * `allowClear`
     *
     * @attribute inputOptions
     * @type {Object}
     * @public
     */
    selectOptions: undefined,
    search: undefined,
    searchField: undefined,
    placeholder: undefined,
    allowClear: undefined,
    componentName: 'power-select',

    didReceiveAttrs() {
      this._super(...arguments);

      this._parseOptions();
    },

    _parseOptions() {
      let options = this.inputOptions || {};
      let selectOptions = options.selectOptions,
          search = options.search,
          searchField = options.searchField,
          placeholder = options.placeholder,
          multiple = options.multiple,
          allowClear = options.allowClear;
      Ember.set(this, 'selectOptions', selectOptions);
      Ember.set(this, 'search', search);
      Ember.set(this, 'searchField', searchField);
      Ember.set(this, 'placeholder', placeholder);
      Ember.set(this, 'allowClear', allowClear);
      Ember.set(this, 'componentName', multiple ? 'power-select-multiple' : 'power-select');
      (false && !(selectOptions) && Ember.assert('You must pass `selectOptions` in the `inputOptions` for the select input.', selectOptions));
    }

  });

  _exports.default = _default;
});